import React, {useEffect, useState} from 'react'
import ToolTips from '../../consts/Tooltip/ToolTips'
import SentenceShorter from '../../consts/SentenceShorter'
import moment from 'moment'
import OpenChat from '../../consts/champChatDashboard/OpenChat'

export const newColumns = (handleViewButtonClick?: any, data?: any,) => [
  {
    name: 'Order ID',
    cell: (row: any) => row?.id,
    sortField: 'id',
    sortable: true,
    reorder: true,
    id: 1,
    width: '200px',
  },
  {
    reorder: true,
    name: 'view ordered items',
    cell: (row: any) => (
      <button
        onClick={() => handleViewButtonClick(row?.id)}
        className='btn btn-sm btn-primary'
      >
        <i className='fa fa-eye '></i>
      </button>
    ),
    id: 2,
    width: '200px',
  },
  {
    name: 'Ordered by',
    cell: (row: any) => {
        return (
            <div className='text-left'>
                <OpenChat title={row?.inventoryOrderCleanerBy?.first_name + " " + row?.inventoryOrderCleanerBy?.last_name} champId={row?.inventoryOrderCleanerBy?.id} trackData={data} />
            </div>
        )},
    sortField: 'inventoryOrderCleanerBy.first_name',
    sortable: true,
    reorder: true,
    id: 4,
    width: '200px',
  },
  {
    name: 'Champ',
    cell: (row: any) => {
      return (
          <div className='text-left'>
              <OpenChat title={row?.inventOryorderCleanerChamp?.first_name + " " + row?.inventOryorderCleanerChamp?.last_name} champId={row?.inventOryorderCleanerChamp?.id} trackData={data} />
          </div>
      )},
    sortField: 'inventOryorderCleanerChamp.first_name',
    sortable: true,
    reorder: true,
    id: 5,
    width: '200px',
  },
  {
    name: 'Area Manager',
    cell: (row: any) => (
      <span>{row?.inventOryorderCleanerChamp?.champSupervisor?.first_name} </span>
    ),
    sortField: 'inventOryorderCleanerChamp.champSupervisor.first_name',
    sortable: true,
    reorder: true,
    id: 8,
    width: '200px',
  },
  {
    name: 'Champ Area',
    cell: (row: any) => row?.inventOryorderCleanerChamp?.cleanerAreas?.[0]?.areaDetail?.areaname,
    sortField: 'inventOryorderCleanerChamp.cleanerAreas.areaDetail.areaname',
    sortable: true,
    reorder: true,
    id: 9,
    width: '200px',
  },  
  {
    name: 'Ordered Date',
    cell: (row: any) => moment(row?.order_date).format('YYYY-MM-DD HH:mm') || 'Unkown date',
    sortField: 'order_date',
    sortable: true,
    reorder: true,
    id: 6,
    width: '200px',
  },
  {
    name: 'Order Status',
    cell: (row: any) => row?.inventoryOrderStatus?.name,
    sortField: 'inventoryOrderStatus.name',
    reorder: true,
    id: 7,
    sortable: true,
    width: '200px',
  },
  {
    name: 'Image',
    cell: (row: any) => {
      const imageUrl = row?.inventoryOrderCompletedDetail?.image;
      return imageUrl ? (
        <a
          href={imageUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={imageUrl} alt="" style={{ width: "50px", height: "50px" }} />
        </a>
      ) : 'No image';
    },
    reorder: true,
    id: 8,
    sortable: false,
    width: '200px',
  },
  {
    name: 'Location',
    cell: (row: any) => {
      const lat = row?.inventoryOrderCompletedDetail?.latitude;
      const lng = row?.inventoryOrderCompletedDetail?.longitude;
      
      return lat && lng ? (
        <a 
          href={`https://www.google.com/maps?q=${lat},${lng}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
        >
          <i className="fas fa-map-marker-alt me-2"></i>
          View on Map
        </a>
      ) : 'No location';
    },
    reorder: true,
    id: 9,
    sortable: false,
    width: '200px',
  },
]
