import { useState } from 'react'
import { KTCardBody } from '../../../_metronic/helpers'
import { Tabs, Tab } from 'react-bootstrap'
import {AttritionData} from './AttritionData'
import { RenewalData } from './RenewalData'

export const Attrition = () => {
  const [activeTab, setActiveTab] = useState('attritionData')

  return (
    <KTCardBody>
      <div className='mb-4 fs-4 fw-semibold'>Attrition Data</div>

      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k || 'attritionData')}
        className='mb-4'
      >
        <Tab eventKey='attritionData' title='Attrition Data'>
          <AttritionData />
        </Tab>
        <Tab eventKey='renewalData' title='Renewal Data'>
          <RenewalData />
        </Tab>
      </Tabs>
    </KTCardBody>
  )
}