import { Dialog } from '@mui/material';
import moment from "moment";
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LocalBaseURL } from '../../../BaseURLmanagement';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import ReturnDay from '../../consts/Static/ReturnDay';
import ReturnTime from '../../consts/Static/ReturnTime';
import JobDetailsModal from './cleaner-items/JobDetailsModal';
import CleanerSingleSelect from './CleanerSingleSelect';
import CleanerTableBodyComponent from './common/CleanerTableBodyComponent';
import MultiSelect from './common/MultiSelect';
import Timing from './Timing';
import TagsShow from '../../consts/TagsShow/TagsShow';
import CleanerAssigningForm from './cleaner-items/CleanerAssigningForm';
import CleanerAssigning from '../../consts/SweetAlert/CleanerAssigning';
import { ColModal, GroupModal, LabelModal, RowModal } from '../../consts/widgets/forms/FormsModals';

import "./cleaner.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import AdminForm from '../admins/adminForms/AdminForm';
import { createTicketformData } from '../NewTicketsList/newTicketListUtils';
const CleanerAvailabilityRoute = (props: {
  iscleanerpage?: boolean
  champAvailtyRender?: any
  champAvailty?: any
  refrence?: any
  from?: any
  getChampDetails?: any
}) => {
  const { iscleanerpage, champAvailty, champAvailtyRender, refrence, from = "self", getChampDetails } = props
  LocalBaseURL()
  const paramsIDS: any = useParams()
  const urlEndPoint = window.location.pathname.split("/")
  const [cleanerStats, setCleanerStats] = React.useState<any>([])
  const [SubscriptionData, setsubscriptionData] = React.useState<any>({})
  const [distenceRadeus, setDistenceRadeus] = React.useState<any>(2)
  const [dates, setDates] = React.useState<any>([])
  const [cleanerList, setCleanerList] = React.useState([])
  const [timeSlots, setTimeSlots] = React.useState<any>([])
  const [timeSlotsfilter, settimeSlotsfilter] = React.useState<any>([])
  // const newTimesloteFilerData = timeSlotsfilter?.filter((ele: any) => ele.id != 5)
  const [timingSlots, setTimingslots] = React.useState<any>([])
  const [SelectedTimingMultiSelect, setSelectedTimingMultiSelect] = React.useState<any>([])
  const [selectedCleaner, setCleaner] = React.useState('0')
  const [cleanerDetails, setcleaner_details] = React.useState<any>({})
  const [CleanerAvailibiltyTimeslot, setCleanerAvailibiltyTimeslot] = React.useState<any>({})
  const [customerAvailibiltyTimeslot, setCustomerAvailibiltyTimeslot] = React.useState<any>({})
  const [getAllTimesloteApiDataListFilter, setGetAllTimesloteApiDataListFilter] = React.useState<any>([])
  const [jobdetailssss, setjobdetails] = React.useState<any>({})
  const [jobDetailsTimeSlot, setjobDetailsTimeSlot] = React.useState('')
  const [jobsiteid, setjobsiteid] = React.useState(0)
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [isCleanerModelOpen, setCleanerModelOpen] = React.useState(false)
  const [Renders, setRenders] = React.useState<any>(null)
  const [isLoading, setLoading] = React.useState(false)
  const [attendencedatefrom, setAttendencedatefrom] = React.useState<any>(moment().day("Monday").format('YYYY-MM-DD'))
  const [attendencedateto, setAttendencedateto] = React.useState<any>(moment().day(7).format('YYYY-MM-DD'))
  const [loading2, setloading2] = React.useState(false)
  const [currentSubscriptionid, setcurrentSubscriptionid] = React.useState<any>(null)
  const [updateCustomerTimeslote, setUpdateCustomerTimeslote] = React.useState(false)

  const [formTimeSloteData, setFormTimeSloteData] = useState<any>([])
  const [formDefaultTimeSlote, setFormDefaultTimeSlote] = useState<any>(null)

  const getCustomerTimeslot = async () => {
    const formTimeSloteData = await getRequest(URL_EndPoints("getCustomerTimeslots")?.getCustomerTimeslots, null)
    setFormTimeSloteData(formTimeSloteData?.data?.data)
  }

  useEffect(() => {
    getCustomerTimeslot()
  }, [])


  useEffect(() => {
    if (SubscriptionData?.masterFrequency?.name == "One Time") {
      setFormTimeSloteData([...formTimeSloteData, { name: "06AM-09AM", id: 22 }])
    }
  }, [SubscriptionData?.masterFrequency?.name])

  async function getAllTimelot() {
    const getTimesloteDataApi = await getRequest(URL_EndPoints()?.getAllTimeSlots, null)
    setGetAllTimesloteApiDataListFilter(getTimesloteDataApi?.data?.data)
  }

  useEffect(() => {

    getAllTimelot()
  }, [])


  useEffect(() => {
    let prevTimeslote: any = SubscriptionData?.timeslotCustomerSelectedDetail
    const filterTimeSlote = formTimeSloteData?.filter((ele: any) => prevTimeslote?.id == ele?.id)?.map((ele: any) => ({ label: ele?.name, value: ele?.id }))[0] || null
    filterTimeSlote && setFormDefaultTimeSlote(filterTimeSlote)
  }, [formTimeSloteData, SubscriptionData?.timeslotCustomerSelectedDetail?.id])

  const data = useMemo(() => cleanerStats, [cleanerStats])
  const refreshAfterAssigning = useSelector((store: any) => store.ActivePaidSubscriptionReducer.cleanerAvailibiltyRoutes)
  React.useEffect(() => {
    paramsIDS?.id && urlEndPoint[urlEndPoint?.length - 2] !== "oldjob" ? setcurrentSubscriptionid(paramsIDS?.id) : setcurrentSubscriptionid(champAvailty)
  }, [champAvailtyRender, paramsIDS?.id])


  React.useEffect(() => {
    async function loadsData() {
      let timeSlotsidfiltered = []
      for (let i = 0; i < SelectedTimingMultiSelect?.length; i++) {
        timeSlotsidfiltered.push(SelectedTimingMultiSelect[i]?.value)
      }
      if (SelectedTimingMultiSelect?.length != 0) {
        let arr = []
        for (let i = 0; i < timingSlots?.length; i++) {
          let payload = {
            name: timingSlots[i]
          }
          arr.push(payload)
        }
        setTimeSlots(arr)
        setloading2(true)
        const payloads = {
          fromDate: attendencedatefrom,
          toDate: attendencedateto,
          subscriptionID: +currentSubscriptionid,
          timeslots: timeSlotsidfiltered || SelectedTimingMultiSelect,
          distenceRadius: +distenceRadeus
        }
        const result = await postRequest(URL_EndPoints()?.getAvalabilitybySubscription, payloads, setloading2)
        setDates(result?.data?.dates)
        settimeSlotsfilter(result?.data?.timeslots)
        setCleanerStats(result?.data?.data)
        setCleanerModelOpen(false)
      }
    }


    refreshAfterAssigning && loadsData()
  }, [refreshAfterAssigning])


  React.useEffect(() => {
    setLoading(true)
    const payloads = {
      fromDate: attendencedatefrom,
      toDate: attendencedateto,
      subscriptionID: +currentSubscriptionid,
      timeslots: [],
      distenceRadius: +distenceRadeus
    }

    async function loadsData() {
      const result = await postRequest(URL_EndPoints()?.getAvalabilitybySubscription, payloads, setLoading)
      setDates(result?.data?.dates)
      settimeSlotsfilter(result?.data?.timeslots)
      setCleanerStats(result?.data?.data)
      setCleanerModelOpen(false)
      setsubscriptionData(result?.data?.subscriptionData)
      setTimeSlots(result?.data?.timeslots)
      const getCleanerListdata = await getRequest(URL_EndPoints(null)?.getCleanerList, null)
      setCleanerList(getCleanerListdata?.data?.data)
    }
    currentSubscriptionid && loadsData()

  }, [currentSubscriptionid, champAvailtyRender])


  const handleFromDateChange = (e: any) => {
    setAttendencedatefrom(e.target.value)
  }
  const handleToDateChange = (e: any) => {
    setAttendencedateto(e.target.value)
  }


  const handleClick = () => {
    async function loadsData() {
      let timeSlotsidfiltered = []
      for (let i = 0; i < SelectedTimingMultiSelect?.length; i++) {
        timeSlotsidfiltered.push(+SelectedTimingMultiSelect[i]?.value)
      }
      if (SelectedTimingMultiSelect?.length != 0) {
        let arr = []
        for (let i = 0; i < timingSlots?.length; i++) {
          let payload = {
            name: timingSlots[i]
          }
          arr.push(payload)
        }
        setTimeSlots(arr)
        setloading2(true)
        const payloads = {
          fromDate: attendencedatefrom,
          toDate: attendencedateto,
          subscriptionID: +currentSubscriptionid,
          timeslots: timeSlotsidfiltered || SelectedTimingMultiSelect,
          distenceRadius: +distenceRadeus
        }
        const result = await postRequest(URL_EndPoints()?.getAvalabilitybySubscription, payloads, setloading2)
        setDates(result?.data?.dates)
        settimeSlotsfilter(result?.data?.timeslots)
        setCleanerStats(result?.data?.data)
        setCleanerModelOpen(false)
      }
      else {
        setloading2(true)
        const payloads = {
          fromDate: attendencedatefrom,
          toDate: attendencedateto,
          subscriptionID: +currentSubscriptionid,
          timeslots: SelectedTimingMultiSelect,
          distenceRadius: +distenceRadeus
        }
        const result = await postRequest(URL_EndPoints()?.getAvalabilitybySubscription, payloads, setloading2)
        setDates(result?.data?.dates)
        settimeSlotsfilter(result?.data?.timeslots)
        setCleanerStats(result?.data?.data)
        setCleanerModelOpen(false)
        setsubscriptionData(result?.data?.subscriptionData)
        setTimeSlots(result?.data?.timeslots)
      }
    }
    loadsData()
  }


  const handleCleanerChange = async (event: any, name: any) => {
    let timeSlotsidfiltered = []
    for (let i = 0; i < SelectedTimingMultiSelect?.length; i++) {
      timeSlotsidfiltered.push(+SelectedTimingMultiSelect[i]?.value)
    }
    if (event) {
      setloading2(true)
      setCleaner(event ? event : 0)
      const payloads = {
        cleanerid: event ? +event : 0,
        fromData: attendencedatefrom,
        timeslots: timeSlotsidfiltered,
        toDate: attendencedateto,
        subscriptionID: +currentSubscriptionid,
        // subscriptionID: +paramsIDS?.id,
        distenceRadius: +distenceRadeus
      }
      const result = await postRequest(URL_EndPoints()?.getAvalabilitybySubscription, payloads, setloading2)
      setCleanerStats(result.data.data)
    }
  }


  const handleDistanceFormData = (e: any) => {
    setDistenceRadeus(e.target.value)
  }

  const handleJobDetailSubmit = (timeslot: any, timeslotSelected: any, jobsiteid: number) => {
    setjobdetails(timeslot)
    setjobDetailsTimeSlot(timeslotSelected)
    setjobsiteid(jobsiteid)
    setModalOpen(true)
  }

  const handleCleanerDetailsSubmit = (cleanerDetails: any, timeslots: any) => {
    let selectedCleanerTimeSlote = getAllTimesloteApiDataListFilter?.find((ele: any) => ele?.name == timeslots?.name)
    let selectedCustomerTimeSlote = getAllTimesloteApiDataListFilter?.find((ele: any) => ele?.id == SubscriptionData?.timeslot)
    from == "subscriptionDataChat" && setRenders(Math.random())
    setCleanerModelOpen(!isCleanerModelOpen)
    setcleaner_details(cleanerDetails)
    setCleanerAvailibiltyTimeslot(selectedCleanerTimeSlote)
    setCustomerAvailibiltyTimeslot(selectedCustomerTimeSlote)
    // setCleanerAvailibiltyTimeslot(timeslots)

    if (from == "champAbsentList") {
      getChampDetails(cleanerDetails)
    }

  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleCloseModalCleaner = () => {
    setCleanerModelOpen(!isCleanerModelOpen)
  }

  let screans = window.innerWidth

  if (isLoading) {
    return (
      <div className='d-flex align-items-center justify-content-center h-75 flex-column'>
        <div className='spinner-border mr-15' role='status'></div>
        <h4 className='fw-bold mt-2'>Loading...</h4>
      </div>
    )
  }



  // SubscriptionData?.timeslotCustomerSelectedDetail ? SubscriptionData?.timeslotCustomerSelectedDetail?.name : SubscriptionData?.timeslotData?.name



  return (
    <>

      <div className='card mb-2 isPosition' style={{ width: "100%", height: "auto", top: refrence !== "outside" ? "69px" : "", zIndex: 99 }}>
        <RowModal classes="d-flex  justify-content-between ">

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-4 mb-2">
            <GroupModal classes=" d-flex flex-column justify-content-center text-center" >

              <div className='my-2 col-12  '>

                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1'>{'Frequency :'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.masterFrequency?.name} </span>
                </div>

                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Subscription Date :'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.startdate} to {SubscriptionData?.enddate}  </span>
                </div>

                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Address  :'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.jobsitename ? SubscriptionData?.jobsitename : "Individual"}  </span>
                </div>


              </div>

            </GroupModal>
          </ColModal>


          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-4 mb-2">
            <GroupModal classes="d-flex flex-column justify-content-center text-center" >

              <div className='my-2  col-12'>
                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Customer Name:'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.name}</span>
                </div>


                <div className='d-flex '>
                  <div className='d-flex flex-wrap'>
                    <TagsShow Tag={SubscriptionData?.private_tag}></TagsShow>
                  </div>
                </div>


                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Customer phone:'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.phone}</span>
                </div>

                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Customer Tag:'}</span>
                  <div className='d-flex flex-wrap'>
                    <TagsShow Tag={SubscriptionData?.ctCustomer?.private_tag ? SubscriptionData?.ctCustomer?.private_tag : "No Tag"}></TagsShow>
                  </div>
                </div>


                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Active Champ :'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.activechamp?.first_name ? SubscriptionData?.activechamp?.first_name + SubscriptionData?.activechamp?.last_name : "No Name"} </span>
                </div>

                <div className='d-flex '>
                  <div className='d-flex flex-wrap'>
                    <TagsShow Tag={SubscriptionData?.activechamp?.private_tag}></TagsShow>
                  </div>
                </div>

              </div>

            </GroupModal>
          </ColModal>


          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-4 mb-2">
            <GroupModal classes="d-flex flex-column justify-content-center text-center" >
              <div className='my-2'>

                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Time:'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.timeslotCustomerSelectedDetail ? SubscriptionData?.timeslotCustomerSelectedDetail?.name : ""} <FontAwesomeIcon icon={faEdit} className='ms-2 text-muted fs-5' onClick={() => setUpdateCustomerTimeslote(true)} /> </span>

                </div>


                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Cleaning:'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>
                    <ReturnDay SelectedData={SubscriptionData}></ReturnDay>
                  </span>
                </div>


                <div className='d-flex row'>
                  <span className='fw-bolder md-fs-5 col-md-auto me-1 '>{'Package Name :'}</span>
                  <span className='text-muted md-fs-5 col-md-auto'>{SubscriptionData?.packageDetail?.name} </span>
                </div>

              </div>

            </GroupModal>
          </ColModal>
        </RowModal >

        <RowModal classes="d-flex  justify-content-between ">

          <ColModal classes="col-12  mb-2">
            <GroupModal classes="text-center" >

              {SubscriptionData?.jobsitename != "Individual" ? <div className='  d-flex flex-row flex-wrap mb-3  justify-content-between align-items-center  text-center'   >
                <div className='d-flex flex-wrap' >
                  {
                    SubscriptionData?.societyWorkingCleaners?.length > 0 ? SubscriptionData?.societyWorkingCleaners?.map((cleanerlist: any) =>
                      <span className='badge badge-light-dark fs-8 fw-bold me-2 '>  {cleanerlist?.first_name + " " + cleanerlist?.last_name}</span>
                    ) : <span className='badge badge-light-dark fs-8 fw-bold me-2  text-center'>No Cleaner Is Available in this society</span>
                  }
                </div>
              </div> : <></>}

            </GroupModal>
          </ColModal>

        </RowModal >

        <RowModal classes="d-flex  justify-content-between g-1 ">

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-3 mb-2">
            <GroupModal classes="" >


              <MultiSelect
                defaultVal={SubscriptionData}
                SelectedTimingMultiSelect={SelectedTimingMultiSelect}
                setSelectedTimingMultiSelect={setSelectedTimingMultiSelect}
                setTimingslots={setTimingslots}
                setTimeSlots={setTimeSlots}
                timeSlotsfilter={timeSlotsfilter}
              ></MultiSelect>


            </GroupModal>
          </ColModal>

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-2 mb-2">
            <GroupModal classes="" >

              <CleanerSingleSelect
                handleChangeInputData={handleCleanerChange}
                HeaderTitle="Select cleaner"
                SelectData={cleanerList}
                DynamicKey={"first_name"}
                DynamicKey2={"last_name"}
                DynamicKey3={"phone"}
                id={"id"}
                name="cleanerid"
              />

            </GroupModal>
          </ColModal>

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-1 mb-2">
            <GroupModal classes="" >

              <input
                type='number'
                className='form-control bg-secondary mb-2 border'
                placeholder='0/km'
                onChange={handleDistanceFormData}
                value={distenceRadeus}
              />

            </GroupModal>
          </ColModal>

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-2 mb-2">
            <GroupModal classes="" >

              <input
                type='date'
                className='form-select form-select-solid me-2 mb-2'
                onChange={handleFromDateChange}
                value={attendencedatefrom}
              />

            </GroupModal>
          </ColModal>

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-2 mb-2">
            <GroupModal classes="" >
              <input
                type='date'
                className='form-select form-select-solid me-2 mb-2'
                onChange={handleToDateChange}
                defaultValue={attendencedateto}
                value={attendencedateto}
              />

            </GroupModal>
          </ColModal>

          <ColModal classes="col-12 col-md-4 col-sm-6 col-lg-1 mb-2">

            <button className='btn btn-sm btn-warning col-12 ' onClick={handleClick}>
              Search
            </button>

          </ColModal>

        </RowModal >

      </div>
      {
        refrence !== "outside" && <>
          <br />
          <br />
          <br />
          <br />
        </>
      }
      {loading2 ? <div className='d-flex justify-content-center h-75' style={{ height: "80vh", }}>
        <div className='spinner-border mr-15 me-3' role='status'></div>
        <h4 className='fw-bold ms-3 mt-2'>Loading...</h4>
      </div> : <div className='card'>
        {
          <div className='table-responsive px-3'>
            {
              distenceRadeus >= 5 && cleanerStats?.length == 0 &&
              <div className='d-flex align-items-center justify-content-center flex-column p-4 mt-10' style={{ width: "auto", height: "auto", position: "absolute", zIndex: 9999999999999999999999, background: "", margin: "auto", top: "50%", left: "30%" }}>
                {/* <i className="bi bi-bug fs-1 fw-bold"></i> */}
                <img src="https://cdn-icons-png.flaticon.com/512/785/785104.png" style={{ width: "100px", marginBottom: "30px" }} alt="" />
                <h2>This customer address might be wrong kindly please check</h2>
              </div>
            }
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer'
            >
              <thead  >
                <tr  >
                  <th style={{ width: '130px' }}>
                    <div className='bg-secondary text-dark  p-2  py-5  text-center fw-bolder rounded'>
                      Cl Name
                    </div>
                  </th>
                  <th>
                    <div className='bg-secondary text-dark  p-2 py-5 text-center fw-bolder rounded'>
                      TimeSlots
                    </div>
                  </th>
                  {dates?.map((item: any) => (
                    <th key={item}>
                      <div
                        className='bg-secondary text-dark  p-2 text-center fw-bolder rounded'
                        style={{ maxWidth: '120px', width: '100px' }}
                      >
                        {moment(item).format('DD-MM')}
                        <br />
                        {moment(item).format('dddd')}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              {
                cleanerStats && <CleanerTableBodyComponent
                    cleanerStats={cleanerStats}
                    timeSlots={timeSlots}
                    handleJobDetailSubmit={handleJobDetailSubmit} handleCleanerDetailsSubmit={handleCleanerDetailsSubmit}
                    ActiveChmapId={SubscriptionData?.activechamp?.id}
                  />
              }
            </table>
          </div>
        }

        {
          isModalOpen && (
            <Dialog
              open={true}
              onClose={handleCloseModal}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <JobDetailsModal filteredData={jobdetailssss} jobsiteid={jobsiteid} jobDetailsTimeSlot={jobDetailsTimeSlot} data={data} handleCloseModal={handleCloseModal} />
            </Dialog>
          )
        }

        {
          isCleanerModelOpen && from == "self" && (
            // isCleanerModelOpen && from != "subscriptionDataChat" && from == "self" && (
            <Dialog
              open={true}
              onClose={handleCloseModalCleaner}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <CleanerAssigningForm referece={urlEndPoint[urlEndPoint?.length - 2]} timeSlotsfilter={timeSlotsfilter} filteredCustomerData={SubscriptionData} cleanerDetails={cleanerDetails} data={data} handleCloseModalCleaner={handleCloseModalCleaner} CleanerAvailibiltyTimeslot={CleanerAvailibiltyTimeslot} customerAvailibiltyTimeslot={customerAvailibiltyTimeslot} />
            </Dialog>
          )
        }


        {
          isCleanerModelOpen && from == "subscriptionDataChat" &&
          <CleanerAssigning render={Renders} confirm={'Yes'} cancel={'No'} payloads={{
            orderid: champAvailty,
            cleanerid: cleanerDetails?.id,
            timeslotId: CleanerAvailibiltyTimeslot?.id ? CleanerAvailibiltyTimeslot?.id : null,
          }}
            handleCloseModalCleaner={handleCloseModalCleaner}
            // setIsDeleted={setRowData}
            // customerDetailData={customerDetailData}
            // setShowBlockForm={setShowBlockForm}
            title={`Are you sure want to Assign `} />
        }

        {
          updateCustomerTimeslote ? <AdminForm
            show={updateCustomerTimeslote}
            setShow={setUpdateCustomerTimeslote}
            refreshNow={handleClick}
            toastMessage="msg"
            formData={[
              {
                HeaderTitle: 'Select Timeslote',
                filterApi: 'staticFormData',
                label: {
                  show: true,
                  name: 'Select Timeslote',
                },
                valueType: 'select',
                inputType: '',
                displayFields: ['name'],
                valueField: 'id',
                name: 'timeslot',
                from: '',
                to: '',
                isRequired: true,
                errMessage: 'Timeslot is required !',
                searchable: true,
                wa_number_id: null,
                userType: null,
              },
            ]}
            staticFormData={formTimeSloteData}
            initialData={{
              subscriptionId: SubscriptionData?.id,
              timeslot: formDefaultTimeSlote ? formDefaultTimeSlote?.value : null,
            }}
            prevInitialData={formDefaultTimeSlote ? { item: formDefaultTimeSlote } : null}
            dynamicApiData={{
              getCustomerTimeslots: {
                url: 'getCustomerTimeslots',
                method: 'GET',
                isQuery: null,
                isParams: null,
                isBody: null,
              }
            }}
            keyName="updateCustomerTimesloteForm"
            formTitle={`Update Customer Timeslote`}
            formSubmitApiUrl="updateSubscriptionCustomerTimeslot"
          /> : <></>
        }
      </div >
      }
    </>
  )
}
export default CleanerAvailabilityRoute
