
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { toast } from 'react-toastify';
import "react-tooltip/dist/react-tooltip.css";
import { KTCardBody } from '../../../_metronic/helpers';
import DropDownFilterMenu from '../../consts/BootrapDropdown/DropDownFilterMenu';
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import IsLoading from '../../consts/IsLoading/IsLoading';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import MomentDate from '../../consts/MomentDate';
import Refresh from '../../consts/Refresh';
import { BulkUtilButton } from '../../consts/reuseModules/BulkUtilButton';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import { cellRenderInner, colWidthsHotTable, getFilteredRowDataNew2, handleAfterSelectionRow, hotInstanceLoader, searchFieldKeyupCallback } from '../admins/adminUtils';
// import SubCategoryListModal from './SubCategoryListModal';
import { AdminToolTip } from '../admins/adminComponents/AdminToolTipModal';
import ShowTicketCountChartModal from '../ticketCountLists/ShowTicketCountChartModal';
import ShowFilterHint from '../ticketCountLists/ticketComponents/ShowFilterHint';
import LeadsVsSourceFilter from './LeadsVsSourceFilter';



registerAllModules();
const intervalType = [{ name: "Daily", id: "daily" }, { name: "Weekly", id: "weekly" }, { name: "Monthly", id: "monthly" }]

const listConfig: any = {
    leadsCount: {
        list_name: "Leads Count Source Wise",
        list_api: "getLeadsCountBasedOnSource",
        list_chart_title: "Show Leads Count Chart",
        keyName: "leadsCount",
        subCategory: false
    },
    sourceVsLead: {
        list_name: "Source Vs Leads Status List",
        list_api: "getSourceVsLeadStatus",
        list_chart_title: "Show Leads Vs Source Chart",
        keyName: "sourceVsLead",
        subCategory: true
    },
}

function convertData(chartData: any, keyName: any) {
    let graphData: any = []
    if (chartData?.includes(undefined)) {
        graphData = chartData?.filter((ele: any) => ele != undefined)
    } else {
        graphData = chartData
    }

    let kyes = Object.keys(graphData[0] || {})
    return {
        categories: kyes,
        series: graphData?.map((element: any) => {
            let keys = Object.keys(element)
            return {
                name: element[keyName],
                data: keys.map((item: any) => element[item])
            }
        })
    }
}

export const LeadsVsSourceSubStatusList = () => {
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
    const [chartData, setChartData] = useState([]);
    const [campaigns, setCampaigns] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: MomentDate(0),
        leads_type: "cleaners",
        campaign: null,
    })

    async function getTicketCountData4List() {
        setFilteredSelectedRows([])
        setPending(true)
        const ticketCountData = await postRequest(URL_EndPoints(null)?.getSourceVsLeadSubStatus, payload, setPending)
        setColumns(ticketCountData?.data?.columns || [])
        setFilterData(ticketCountData?.data?.data || [])
        setData(ticketCountData?.data?.data || [])
    }

    const initialRender = async () => {
        getTicketCountData4List()
        const campaigns = await getRequest(URL_EndPoints(null)?.getSourceCampaigns, null)
        setCampaigns(campaigns?.data?.data || [])
    }
    
    useEffect(() => {
        initialRender()
    }, [])

    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
        let x = setTimeout(() => {
            const filterSearchData = AllFielSearch(data, search)
            firstTimeNotRun?.current === true && setFilterData(filterSearchData)
            if (firstTimeNotRun?.current === false) {
                firstTimeNotRun.current = true
            }
        }, 2000)
        return () => {
            clearTimeout(x)
        }
    }, [search])

    const searchHandler = () => {
        getTicketCountData4List()
    }

    const onChangeHandler = (event: any, name: any) => {
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }


    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }

    const [showChart, setShowChart] = useState(false)
    const [showSubCategoryModal, setShowSubCategoryModal] = useState(false)

    const showChartHandler = (value: boolean) => {
        if (filteredSelectedRows?.length == 0) {
            toast.error("⚠️ Please select some rows in bulk to see chart", { position: "top-center" })
            return
        }
        setShowChart(value)
    }
    // const showSubCategory = () => {
    //     if (chartData?.length == 1) {
    //         setShowSubCategoryModal(true)
    //         return
    //     }
    //     toast.error("⚠️ Plese Select Single Row ", { position: "top-center" })
    // }

    // hot table code ------------------------------------
    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);

    const cellRenderer = (...args: any) => {
        cellRenderInner(args, Handsontable)
    }

    const shortWords = (sentence: any, index: any) => {
        if (index < 1) { return removeUnderScore(sentence) }
        else {
            let str = sentence?.split(" ")?.reduce((str: any, ele: any) => {
                str += ele[0] + " "
                return str
            }, "")
            return str
        }
    }

    const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
        handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    };

 

    return (
        <>
            <KTCardBody>
                <div className='mb-4 fs-4 fw-semibold'>Source Vs Leads Status List</div>
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                    <RightBox>
                        <Refresh refreshNow={searchHandler} />
                        {/* <ExcelExportButton2 tableName=".leadsCountSourceWise" /> */}
                        {/* {
                            listConfig[payload?.list_type]?.subCategory && <BulkUtilButton title="Sub Category List" filteredSelectedRows="static" setShow={showSubCategory} />
                        } */}
                        {/* <BulkUtilButton title={"Show Source Vs Leads Chart"} filteredSelectedRows="static" setShow={showChartHandler} /> */}
                        {
                            <DropDownFilterMenu
                                ParentData={{
                                    handleActions: searchHandler,
                                    payloads: payload,
                                    onChangeHandler: onChangeHandler,
                                    intervalType: intervalType,
                                    defaultListType: { label: "Cleaners", value: "cleaners" },
                                    showListType: true,
                                    campaigns: campaigns
                                }}
                                Component={LeadsVsSourceFilter}
                            ></DropDownFilterMenu>
                        }
                    </RightBox>
                </MainContainerWrapper>
                {/* hint components */}
                <ShowFilterHint payload={payload} />

                {pending ? <IsLoading /> : <div >

                    <HotTable
                        ref={hotTableComponentRef}
                        style={{ zIndex: 1 }}
                        data={filterData}
                        columns={columns?.map((ele: any, index: any) => {
                            return {
                                title: renderToString(<div id={index} >{shortWords(ele, index)}  </div>),
                                type: 'text',
                                data: ele,
                                renderer: cellRenderer
                            }
                        })}
                        className='leadsCountSourceWise'
                        filters={true}
                        dropdownMenu={true}
                      
                        colWidths={colWidthsHotTable(columns, 1, 150, 55)}
                        width="100%"
                        height={"80vh"}
                        rowHeaders={true}
                        colHeaders={true}
                        fixedColumnsLeft={1}
                        contextMenu={true}
                        manualColumnFreeze={true}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        licenseKey="non-commercial-and-evaluation"
                        autoRowSize={true}
                        autoColumnSize={true}
                        readOnly={true}
                        manualRowResize={true}
                        manualColumnResize={true}
                        manualRowMove={true}
                        multiColumnSorting={true}
                        key="leavdsvssource"
                        search={{ callback: searchFieldKeyupCallback }}
                        afterSelection={handleAfterSelection}
                        // afterDeselect={handleAfterDeselect}

                    />
                </div>}

            </KTCardBody>

            {columns?.map((ele: any, index: any) => (
                <AdminToolTip anchorId={index} title={ele} place={""} />
            ))}

            {
                showChart ? <ShowTicketCountChartModal
                    chartData={convertData(getFilteredRowDataNew2(filteredSelectedRows, filterData), "source")}
                    show={showChart}
                    setShow={setShowChart}
                    payload={payload}
                    title={"Source Vs Leads Status"}
                /> : <></>
            }

            {/* {
        showSubCategoryModal ? <SubCategoryListModal
          show={showSubCategoryModal}
          setShow={setShowSubCategoryModal}
          intervalType={intervalType}
          filteredSelectedRows={chartData}
        /> : <></>
      } */}
        </>
    );
};

