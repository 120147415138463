import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const RowColumnAction = ({ row, name, setShow, icon, classname, badgeCss, randomValue, }: any) => {
    return (
        <>
            <span className={badgeCss ? badgeCss : "me-3"}>{name}</span> {setShow && <FontAwesomeIcon icon={icon} className={classname} onClick={() => setShow(randomValue ? Math.random() : true)} />}
        </>
    )
}

export default RowColumnAction
// const setUpdatedData = (payload: any) => {
//     rowData.first_due_date = payload?.first_due_date
//     setRowData(rowData)
// }

{/* <RowColumnAction
row={rowData}
name={dateFormater(row?.first_due_date) ?? "NA"}
setShow={setShow}
icon={faEdit}
classname={`fs-4 text-muted`}
randomValue={false}
/> */}