import { useEffect, useState } from "react";
import uuid from "react-uuid";
import SentenceShorter from "../../consts/SentenceShorter";
import ToolTips from "../../consts/Tooltip/ToolTips";
import { postRequest } from "../../consts/Server/Requests";
import { URL_EndPoints } from "../../consts/Server/URL_EndPoints";
import Call_logs from "../Old_JobList/Components/InputBox/Call_logs";
import { DateFormate } from "../../consts/toggleColumns/DateFormate";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ReasonStatusFrom from "./ReasonStatus/ReasonStatusFrom";
import LastStatusform from "./LastStatusEditor/LastStatusform";
import ChipsInfos from "../../consts/Chips/ChipsInfos";
import { ticketStatusToColor } from "../../consts/CommonData";
import GChatBTN from "../../consts/Chats/ChatButtons/GChatBTN";
import { toast } from "react-toastify";
import IsWhatsAppEnabled from "../../consts/components/IsWhatsAppEnabled";
import Chats from "../../consts/Chats/Chats";
import Calling from "../../consts/Call/Calling";
import Layout from "../../consts/Call/Layout";
import DueDateForm from "./DueDateUpdate/DueDateForm";
import { catchCurrentOpenedUrl } from "../../consts/components/Utility";
import TicketStatus from "../../consts/reuseModules/TicketStatus";
import { getFullName } from "../../utils/helper.util";
import AdminDueDateForm from "../admins/adminComponents/AdminDueDateForm";
import { getName } from "../admins/adminUtils";
import RowColumnAction from "../admins/RowColumnAction";
import AdminYesNoAlertAction from "../admins/adminComponents/AdminYesNoAlertAction";
import AdminMultipleYesNoCancel from "../admins/adminComponents/AdminMultipleYesNoCancel";

export const columns = (handleFetchAllCallLogs?: any, customerData?: any, refreshNow?: any) => [

    {
        id: 1,
        reorder: true,
        name: "Customer Name",
        cell: (row: any) => <div className='d-flex g-4 flex-wrap'>
            <div className='text-left '>
                <div className='d-flex flex-row  text-hover-primary cursor-pointer '>

                    <IsWhatsAppEnabled
                        row={row}
                        Ids={row?.id}
                        templateType={1}
                        keyName="customerIds"
                        ApiEndUrl="sendBulkWhatsAppTemplate"
                        to="Customer"
                        IsRequired={false}
                    />
                    <GChatBTN ticketDataParent={{
                        id: row?.vehicleInactiveTicket?.id ?? "",
                        customerid: row?.vehicleCustomer?.id,
                        referenceTicket: "renewalLists"
                    }}
                        trackData={customerData}
                        keyName={catchCurrentOpenedUrl()}
                        Title={row?.vehicleCustomer?.first_name ?? 'NA'} Toggleid={"kt_drawer_chat2_toggle2"}
                        currentRowDataFromList={row}
                        icons={""}
                        tableData={row}
                        refs=""
                        table="renewalLists"
                    ></GChatBTN>
                    <Calling title={""} row_id={row?.vehicleCustomer?.id} row={row} type="customerid" From={Layout} isOpen={false} />
                </div>
            </div>

        </div>,

        sortField: 'vehicleCustomer.first_name',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 2,
        reorder: true,
        name: "Customer ID",
        cell: (row: any) => row?.customer_id,
        sortField: 'customer_id',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 3,
        reorder: true,
        name: <CallDetailColumn handleFetchAllCallLogs={handleFetchAllCallLogs} />,
        cell: (row: any) => <CallDtails row={row} />,
        minWidth: "280px"
    },
    {
        id: 4,
        reorder: true,
        name: "Reason",
        cell: (row: any) => <ReasonUpdate row={row} refreshNow={refreshNow}></ReasonUpdate>,
        sortable: true,
        sortField: 'vehicleInactiveSubscription.ctNotAtHome.reason_id',
        minWidth: "250px"
    },
    {
        id: 5,
        reorder: true,
        name: "Inactive Start",
        cell: (row: any) => row?.inactive_ts ? <DateFormate date={row?.inactive_ts} from="Customer_Vehicle_Start_Date" /> : "NA",
        sortField: 'inactive_ts',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 6,
        reorder: true,
        name: "Inactive End",
        cell: (row: any) => row?.vehicleInactiveSubscription?.ctNotAtHome?.enddate ? <DateFormate date={row?.vehicleInactiveSubscription?.ctNotAtHome?.enddate} from="Customer_Vehicle_End_Date" /> : "NA",
        sortField: 'vehicleInactiveSubscription.ctNotAtHome.enddate',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 7,
        reorder: true,
        name: "Campaign Status",
        cell: (row: any) => <LastStatusEditor row={row}></LastStatusEditor>,
        sortField: 'vehicleInactiveSubscription.renewal_reason_id',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 8,
        reorder: true,
        name: "Ticket Status",
        cell: (row: any) => <TicketStatus ticketStatus={row?.vehicleInactiveTicket?.ticketstatus?.name} ticketStatusId={row?.vehicleInactiveTicket?.ticketstatus?.id} />,
        sortField: 'vehicleInactiveTicket.ticketstatus.name',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 9,
        reorder: true,
        name: "Vehicle Status",
        cell: (row: any) => row?.active_status === 0 ? 'Prospect' : row?.vehicleActiveStatus?.name,
        sortField: 'vehicleActiveStatus.name',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 10,
        reorder: true,
        name: "Customer Status",
        cell: (row: any) => row?.vehicleCustomer?.customerActiveStatus?.name ?? 'Prospect',
        sortField: 'vehicleCustomer.customerActiveStatus.name',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 25,
        reorder: true,
        name: "Can Call",
        cell: (row: any) => <CanCall row={row} />,
        sortField: 'vehicleCallStatus.name',
        sortable: true,
        minWidth: "100px"
    },
    {
        id: 11,
        reorder: true,
        name: "Due Date",
        cell: (row: any) => <div className=''>
            <VehicleListDueDateUpdate row={row}></VehicleListDueDateUpdate>
        </div>,
        sortField: 'vehicleInactiveTicket.last_date_resolution',
        sortable: true,
        width: "200px"
    },
    {
        id: 12,
        reorder: true,
        name: "Months",
        cell: (row: any) => row?.vehicleInactiveSubscription?.transactionid,
        minWidth: "200px"
    },
    {
        id: 13,
        reorder: true,
        name: "Balance Amount",
        cell: (row: any) => row?.fineOrExtra?.fineAmount ? row?.fineOrExtra?.fineAmount : (!row?.vehicleInactiveSubscription?.fineOrExtra?.discountprice ? 0 : row?.vehicleInactiveSubscription?.payment_mode === "wallet" ? '+ ' + row?.vehicleInactiveSubscription?.fineOrExtra?.discountprice + " " + "Wallet" : '- ' + row?.vehicleInactiveSubscription?.fineOrExtra?.discountprice),
        minWidth: "200px"
    },
    {
        id: 14,
        reorder: true,
        name: "Balance Services",
        cell: (row: any) => row?.vehicleInactiveSubscription?.remainingJobs?.map((job: any) => job?.job_name?.split(" ")?.map((word: string) => word[0])?.join("") + ":" + job?.count).join(", "),
        minWidth: "200px"
    },
    {
        id: 15,
        reorder: true,
        name: "Ticket Owner",
        cell: (row: any) => {
            return (row?.vehicleInactiveTicket?.ticketadmin?.first_name) || 'Not Available'
        },
        sortField: "vehicleInactiveTicket.ticketadmin.first_name",
        sortable: true,
        minWidth: "150px"
    },
    {
        id: 16,
        reorder: true,
        name: "Ticket Assigns",
        cell: (row: any) => {
            if (row?.vehicleInactiveTicket?.ticketAssigns?.length === 0) {
                return "Not Assigned";
            }
            const data = row?.vehicleInactiveTicket?.ticketAssigns?.map((assign: any) => <ChipsInfos SelectedString={assign?.userDetail?.first_name || 'Unkown name'} classes="primary" />)
            return data;
        },
        minWidth: "200px"
    },
    {
        id: 17,
        reorder: true,
        name: "Ticket Updated At",
        cell: (row: any) => row?.vehicleInactiveTicket?.ticket_updated_at ? <DateFormate date={row?.vehicleInactiveTicket?.ticket_updated_at} from="Customer_Vehicle_Ticket_Updated_At" /> : "NA",
        sortField: 'vehicleInactiveTicket.ticket_updated_at',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 18,
        reorder: true,
        name: "Batch",
        cell: (row: any) => row?.vehicleBatch?.name || 'Not Set',
        sortField: 'vehicleBatch.name',
        sortable: true,
        minWidth: "200px"
    },
    {
        id: 19,
        reorder: true,
        name: "Wave Off",
        cell: (row: any) => <WaveOffhandler row={row} />,
        width: "200px",
    },
    {
        id: 20,
        reorder: true,
        name: "Inactivated By",
        cell: (row: any) => getFullName(row?.inactiveatedByAdmin),
        sortField: 'inactiveatedByAdmin.first_name',
        sortable: true,
        width: "200px",
    },
];

const CanCall = ({ row }: any) => {
    const [show, setShow] = useState<any>(null)
    const [rowData, setRowData] = useState<any>(row)
    useEffect(() => { setRowData(row) }, [])

    const setUpdatePrevState = (payload: any) => {
        rowData.vehicleCallStatus = rowData.vehicleCallStatus ? rowData.vehicleCallStatus : {}
        rowData.vehicleCallStatus.name = payload?.callStatus == 1 ? "Yes" : payload?.callStatus == 2 ? "No" : "Not Set"
        setRowData(rowData)
    }

    return <>
        <RowColumnAction
            row={rowData}
            name={rowData?.vehicleCallStatus?.name ? rowData?.vehicleCallStatus?.name : 'Not Set'}
            setShow={setShow}
            icon={faEdit}
            classname={`fs-3 text-muted cursor-pointer`}
            randomValue={true}
        />
        {
            show ? <div key="canCallStatus">
                <AdminMultipleYesNoCancel
                    confirm='Yes'
                    cancel='No'
                    successTitle={`Do you want to allow call ?`}
                    FailedTitle='Changes Cancelled '
                    payload={{
                        vehicleId: rowData?.id,
                        callStatus: 0,
                    }}
                    updateNewPayload={(payload: any, action: any) => {
                        let newPayload = JSON.parse(JSON.stringify(payload))
                        if (action) {
                            newPayload.callStatus = 1
                        }
                        else {
                            newPayload.callStatus = 2
                        }
                        return newPayload
                    }}
                    show={show}
                    setShow={setShow}
                    setUpdatePrevState={setUpdatePrevState}
                    refreshNow={null}
                    setPending={null}
                    urlEndPoint="updateVehicleCallStatus"
                    row={null}
                />
            </div> : <></>
        }

    </>
}

const CallDetailColumn = ({ handleFetchAllCallLogs }: any) => {
    return <>
        Call Details <button className='btn btn-primary btn-sm btn-rounded' onClick={handleFetchAllCallLogs} style={{
            fontSize: '10px',
            padding: '5px 7px',
            marginLeft: '7px'
        }}>Fetch All</button>
    </>
}

const CallDtails = ({ row }: any) => {
    const [storedRow, setStoredRow] = useState<any>(row);

    useEffect(() => {
        setStoredRow(row);
    }, [row]);

    return <div className='card-title'>
        <div className='symbol-group symbol-hover'>
            {storedRow?.callLogs?.map((ele: any, indexs: any) => {
                let id = uuid()
                let StartTime = ele?.FormattedStartTime?.split(" ")
                let sentence = ele?.FromDetails?.name + ' - ' + ((StartTime[0] + " " + StartTime[1]))
                if (indexs < 5) {
                    return <div className='symbol symbol-40px symbol-circle' id={id} key={id}  >
                        <span className={`symbol-label bg-light-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : ele?.Status === "no-answer" ? "secondary" : ele?.Status === "canceled" ? "danger" : "primary"} text-${ele?.Status === "completed" ? "success" : ele?.Status === "failed" ? "warning" : "primary"} 40px   fs-7`} >{StartTime[0]} <SentenceShorter sentence={StartTime[1]} index={null} /></span>
                        <ToolTips toolTipId={id} ToolTipShowData={sentence} place={""} />
                    </div>
                }
            })}
            {/* _______________________________________________________________________ */}
            <CallLogsDetails customerid={storedRow?.customer_id} customer_name={storedRow?.vehicleCustomer?.first_name + ' ' + row?.vehicleCustomer?.last_name} lengths={storedRow?.callLogs?.length} row={storedRow} setStoredRow={setStoredRow}></CallLogsDetails>
        </div>
    </div>
}

const CallLogsDetails = ({ customerid, customer_name, lengths, row, setStoredRow }: any) => {
    const [show, setshow] = useState(false)
    const [Cusomerid, setCusomerid] = useState(0)
    const handleFetchCallDetails = async (customerid: number) => {
        if (lengths === undefined) {
            const response: any = await postRequest(URL_EndPoints()?.getCallHistoryByCustomerIds, {
                customerIds: [customerid]
            }, null);
            const callLogs: any = response?.data?.data[0];
            setStoredRow({ ...row, callLogs });
            setCusomerid(customerid)
        } else {
            setshow(true)
            setCusomerid(customerid)
        }
    }
    const handleClose = () => {
        setshow(false)
    }
    return (
        <>
            <a
                href='#'
                className='symbol symbol-40px symbol-circle'
                id={customerid}
                onClick={(e: any) => {
                    e.preventDefault();
                    handleFetchCallDetails(customerid);
                }}
            >
                <span
                    className='symbol-label fs-8 fw-bolder bg-primary text-white'
                // data-bs-toggle='    '
                // data-bs-trigger='hover'
                // title='View more users'
                >
                    {lengths === undefined ? 'Fetch' : lengths}
                </span>
                <ToolTips toolTipId={customerid} ToolTipShowData={lengths === undefined ? 'Fetch' : lengths} place={""} />
            </a>
            <Call_logs FilteredData={{
                call_log_cusomerid: Cusomerid,
                show: show,
                handleClose,
                customer_name
            }} >
            </Call_logs>
        </>
    )
}

const ReasonUpdate = ({ row, refreshNow }: any) => {
    const statusUpdatedData = useSelector((store: any) => store.ReasonsListDataReducer.Master_Reason)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [showRes, setshowRes] = useState<any>(null)
    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        const updatedData = statusUpdatedData?.find((ele: any) => {
            return ele?.id === storeRowP2P?.vehicleInactiveSubscription?.ctNotAtHome?.masterreason?.id
        })
        updatedData && setshowRes(updatedData)
    }, [statusUpdatedData, storeRowP2P?.vehicleInactiveSubscription?.ctNotAtHome?.masterreason?.id])
    const handleClose = () => {
        setupdateStatusPop(false)
    }
    useEffect(() => {
        setStoreRowP2p(row)
    }, [])

    return <>
        <span >
            <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;{showRes?.name ? showRes?.name : 'Not Set'}
        </span>
        {
            updateStatusPop ? <ReasonStatusFrom
                FilteredData={{
                    show: updateStatusPop,
                    handleCloseForm: handleClose,
                    selectedId: {
                        reason_id: row?.vehicleInactiveSubscription?.ctNotAtHome?.masterreason?.id,
                        notathome_id: row?.vehicleInactiveSubscription?.ctNotAtHome?.id
                    }
                }}
                setStoreRowP2p={setStoreRowP2p}
                row={row}
                defRes={showRes}
                refreshNow={refreshNow}
            ></ReasonStatusFrom> : <></>
        }
    </>
}

const LastStatusEditor = ({ row }: any) => {
    const statusUpdatedData = useSelector((store: any) => store.ReasonsListDataReducer.renewalReason)
    const [storeRowP2P, setStoreRowP2p] = useState(row)
    const [updateStatusPop, setupdateStatusPop] = useState(false)
    const [StatusUpdatedDataList, setStatusUpdatedDataList] = useState<any>([])
    const [showRes, setshowRes] = useState<any>({})
    useEffect(() => {
        setStoreRowP2p(row)
    }, [row])

    useEffect(() => {
        statusUpdatedData?.length > 0 && setStatusUpdatedDataList(statusUpdatedData)
    }, [statusUpdatedData])

    useEffect(() => {
        const updatedData = StatusUpdatedDataList?.filter((ele: any) => {
            return ele?.id === storeRowP2P?.vehicleInactiveSubscription?.renewal_reason_id
        })
        setshowRes(updatedData?.length > 0 ? updatedData[0] : { name: "No Set" })
    }, [StatusUpdatedDataList, storeRowP2P?.vehicleInactiveSubscription?.renewal_reason_id])

    const handleClose = () => {
        setupdateStatusPop(false)
    }

    return <>
        <span >
            <FontAwesomeIcon onClick={() => setupdateStatusPop(true)} icon={faEdit} /> &nbsp;{showRes?.name || 'Not Set'}
        </span>
        <LastStatusform
            FilteredData={{
                show: updateStatusPop,
                handleCloseForm: handleClose,
                selectedId: {
                    subscriptionid: row?.vehicleInactiveSubscription?.id
                }
            }}
            setStoreRowP2p={setStoreRowP2p}
            row={row}
            StatusUpdatedData={StatusUpdatedDataList}
            defaultCampaign={{ label: showRes?.name, value: showRes?.id }}
        ></LastStatusform>
    </>
}

const WaveOffhandler = ({ row }: any) => {
    let [loader, setLoader] = useState(false)
    const [waveOffmessage, setWaveOffMessage] = useState("Wave Off")
    const WaveOffHandlerRequest = async (row: any) => {
        setLoader(true)
        setWaveOffMessage("Please Wait..")
        const waveOffResponse = await postRequest(URL_EndPoints(null).subscriptionwaveoff, { subscriptionid: row?.vehicleInactiveSubscription?.id ?? 0 }, setLoader)
        if (waveOffResponse?.data?.message) {
            toast.success(waveOffResponse?.data?.message, { position: "top-center" })
            setWaveOffMessage("Succeeded")
        }
        else {
            toast.error(waveOffResponse?.data?.message, { position: "top-center" })
            setWaveOffMessage("Failed")
            setLoader(false)
        }
    }
    return <>
        <button disabled={loader} onClick={() => WaveOffHandlerRequest(row)} className={`btn btn-sm btn-${waveOffmessage == "Wave Off" ? "dark" : waveOffmessage == "Succeeded" ? "success" : "danger"}`}>{waveOffmessage}</button>
    </>
}

const userid = JSON.parse(localStorage.getItem("user") || "0")
const VehicleListDueDateUpdate = ({ row }: any) => {
    const [showDueDatePopup, setShowDueDatePopup] = useState<any>(false)
    const [storeRow, setStoreRow] = useState<any>(row)
    const handleCloseForm = () => setShowDueDatePopup(false)

    useEffect(() => {
        setStoreRow(row)
    }, [row])

    const setUpdatedDataDueDate = (payload: any) => {
        row.vehicleInactiveTicket = payload?.response
        setStoreRow(row)
    }
    return <>
        <span >
            <FontAwesomeIcon onClick={() => setShowDueDatePopup(true)} icon={faEdit} /> &nbsp;{storeRow?.vehicleInactiveTicket ? <DateFormate date={storeRow?.vehicleInactiveTicket?.last_date_resolution} from="VehicleTypeList_Due_Date" />
                : 'Not Set'}
        </span>


        {
            showDueDatePopup ? <AdminDueDateForm
                show={showDueDatePopup}
                setShow={setShowDueDatePopup}
                setUpdatedData={setUpdatedDataDueDate}
                prevInitialPayload={{
                    ticket_history_id: 0,
                    ticket_id: storeRow?.vehicleInactiveTicket?.id ?? 0,
                    customerid: storeRow?.customer_id,
                    user_id: userid,
                    prev_due_date: '',
                    dueDate: storeRow?.vehicleInactiveTicket?.last_date_resolution,
                    due_date: storeRow?.vehicleInactiveTicket?.last_date_resolution,
                }}

                row={row}
                title="Due Date Update"
                apiUrl="updateDueDateVisitor"
                dueDateKeyName="due_date"
                isDateTime={true}
                isServerDueDate={false}
                serverApiUrl="xxxxx"
            /> : <></>

        }

        {/* 



        {
            <DueDateForm FilteredData={{
                show: showDueDatePopup,
                ticket_history_id: 0,
                ticket_id: storeRow?.vehicleInactiveTicket?.id ?? 0,
                customerid: storeRow?.customer_id,
                user_id: userid,
                prev_due_date: '',
                dueDate: storeRow?.vehicleInactiveTicket?.last_date_resolution,
            }} handleCloseForm={handleCloseForm} setStoreRow={setStoreRow} row={row} />
        } */}
    </>
}