import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { URL_EndPoints } from '../../Server/URL_EndPoints'
import { getRequest, postRequest } from '../../Server/Requests'
import { toast } from 'react-toastify'
import AddPrivatetTag from '../actions/AddPrivatetTag'
import { DefaultPrivatePublic, champAvailibilityFormData, champBuddyFormData, champDashboardSubAreaActionFormData } from '../utils/DefaultPrivatePublic'
import AddPublicTag from '../actions/AddPublicTag'
import ChampPhoneNumberUpdate from '../actions/ChampPhoneNumberUpdate'
import IsBlockAlertChamp from '../actions/IsBlockAlertChamp'
import ChampNameUpdate from '../actions/ChampNameUpdate'
import ChampEmailUpdate from '../actions/ChampEmailUpdate'
import { isAllowedChampNumber, isAllowedCopyNumber } from '../../components/Utility'
import AddAreaName from './champAreaname/AddAreaName'
import UpdateAreaNameList from './champAreaname/UpdateAreaNameList'
import DeleteAreaName from './champAreaname/DeleteAreaName'
import UpdateReferByChamp from '../actions/UpdateReferByChamp'
import IsPaidChampStatus from '../actions/IsPaidChampStatus'
import AddChampRequirementType from '../actions/AddChampRequirementType'
import SubAreaAssignForm from '../../../modules/VisitorListChamp/SubAreaAssignForm'
import WhatsAppNumberUpdate from './WhatsAppNumberUpdate'
import InformationChip from '../../Chats/Components/InformationChip'
import AdminChampActionForm from '../../../modules/admins/adminChampDashboardForms/AdminChampActionForm'
import AdminYesNoAlertAction from '../../../modules/admins/adminComponents/AdminYesNoAlertAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ChampRowDashboardAction from '../../../modules/admins/adminComponents/ChampRowDashboardAction'
import { adminParamsGenerator, adminQueryGenerator } from '../../../modules/admins/adminUtils'
import MaskedPhoneNumber from '../../MaskedPhoneNumber'
import ToolTips from '../../Tooltip/ToolTips'
import copy from 'copy-to-clipboard'

const ChampProfile = ({ ChampDetail, supervisorData }: any) => {
    console.log('ChampDetail', ChampDetail);

    // champAvailabilities
    const userid: any = JSON.parse(localStorage.getItem("user") || "")
    const [ShowBlockForm, setShowBlockForm] = useState(false)
    const [showIsPaidModal, setShowIsPaidModal] = useState(false)
    const [showReferModal, setShowReferModal] = useState(false)
    const [showRequirementTypeModal, setShowRequirementTypeModal] = useState(false)
    const [showSubArea, setShowSubArea] = useState(false)
    const [PhoneNumberUpdateShow, setPhoneNumberUpdateShow] = useState(false)
    const [showAvailibility, setShowAvailibilty] = useState(false)
    const [showBuddy, setShowBuddy] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openModal2, setOpenModal2] = useState(false)
    const [renderIsPaid, setRendersIsPaid] = useState<any>(null)
    const ticketDataRedux = useSelector((store: any) => store.ChatReducers.TicketData)
    const [states, setStates] = useState<string[]>([])
    const [show, setShow] = useState<boolean>(false)
    const [addAreaModal, setAddAreaModal] = useState<boolean>(false)
    const [updateAreaModal, setUpdateAreaModal] = useState<boolean>(false)
    const [updateAreaDetails, setUpdateAreaDetails] = useState<any>({})
    const [deleteAreaDetails, setdeleteAreaDetails] = useState<any>({})
    const [deleteAreaModal, setDeleteArea] = useState<any>(null)
    const [selectedTag, setselectedTag] = useState<any>("")
    const [Renders, setRenders] = useState<any>(null)
    const [phoneNumber, setPhoneNumber] = useState<any>(ChampDetail?.phone ?? null)
    const [loader, setLoader] = useState(false)
    const [disable, setDisable] = useState<any>(null)
    const [RowData, setRowData] = useState<any>(ChampDetail)

    const setUpdatePrevStateBlock = (updatedState: any, row: any) => {
        ChampDetail.accountDeleted = updatedState?.isblock
        setRowData(ChampDetail)
    }

    useEffect(() => { setRowData(ChampDetail) }, [ChampDetail])
    useEffect(() => { setPhoneNumber(ChampDetail?.phone) }, [ChampDetail?.phone])

    const [AreaList, setAreaList] = useState<any>([])
    const [cleanerListData, setCleanerListData] = useState<any>([])

    useEffect(() => {
        async function areaName() {
            const response = await getRequest(URL_EndPoints()?.getarea, null)
            setAreaList(response?.data?.data)
            const getCleanerList = await getRequest(URL_EndPoints(null)?.getCleanerList, null)
            setCleanerListData(getCleanerList.data.data)
        }
        areaName()
    }, [])

    const updateAreaHandler = (areaObj: any) => {
        setUpdateAreaDetails(areaObj)
        setUpdateAreaModal(true)
    }

    const deleteAreaHandler = (areaObj: any) => {
        setdeleteAreaDetails(areaObj)
        setDeleteArea(Math.random())
    }

    const [isLoginBlocked, setIsLoginBlocked] = useState<any>(null)
    const [isVisibleInChampLeads, setIsVisibleInChampLeads] = useState<any>(null)

    const blockunblockHandler = () => {
        if (ChampDetail?.id) {
            setShowBlockForm(true)
            setRenders(Math.random())
        } else {
            toast.error("Sorry not allowed to perform this action 0", { position: "top-center" })

        }
    }
    const isPaidHandler = () => {

        if (RowData?.champReferralRequest) {
            toast.error("Already requested for  payment ", { position: "top-center" })
            return
        }
        else {
            setShowIsPaidModal(true)
            setRendersIsPaid(Math.random())
        }
    }

    const champRequirementHandler = () => {

        // if (1 == 2) {
        //     toast.error("Already requested for  payment ", { position: "top-center" })
        //     return
        // }
        // else {
        setShowRequirementTypeModal(true)
        // }
    }

    const referByHandler = () => {
        if (RowData?.cleanerReferBy?.first_name || RowData?.cleanerReferBy?.last_name) {
            toast.error("Not Allowed once Updated the refer by ", { position: "top-center" })
        }
        else {
            setShowReferModal(true)
        }
    }

    const PhoneNummberUpdate = async () => {

        if (ChampDetail?.id) {
            setLoader(true)
            const phoneNumberUpdates: any = await postRequest(URL_EndPoints(null)?.updateChampPhone, {
                champId: ChampDetail?.id,
                newPhoneNumber: +phoneNumber,
                userid: userid,
            }, setLoader)
            if (phoneNumberUpdates?.data?.status === 200) {
                toast.success(phoneNumberUpdates?.data?.msg)
                ChampDetail.phone = phoneNumber
                setRowData(ChampDetail)
                setPhoneNumberUpdateShow(false)
                setLoader(false)
                setDisable(null)

            }
            else {
                toast.error(phoneNumberUpdates?.data?.msg)

            }
        }
    }


    const handleEditTags = async (params: string) => {
        if (ChampDetail?.id) {
            setDisable(null)
            if (params === "Private Tags") {
                const prevData = await DefaultPrivatePublic(ChampDetail, ChampDetail?.private_tag === "" || ChampDetail?.private_tag == null ? [] : ChampDetail?.private_tag.split(","))
                setStates(prevData as any)
                setselectedTag(params)
            }
            else {
                const prevData = await DefaultPrivatePublic(ChampDetail, ChampDetail?.public_tag === "" || ChampDetail?.public_tag == null ? [] : ChampDetail?.public_tag.split(","))
                setStates(prevData as any)
                setselectedTag(params)
            }
            setShow(true)
        } else {
            toast.error("Sorry not allowed to perform this action 1")
        }

    }

    const closeBtn = () => {
        setShow(false)
        setPhoneNumberUpdateShow(false)
        setOpenModal(false)
        setOpenModal2(false)
        setShowReferModal(false)
        setShowIsPaidModal(false)
        setShowRequirementTypeModal(false)
        setShowSubArea(false)
    }

    const [whatsAppNumberUpdateShow, setWhatsAppNumberUpdateShow] = useState(false)
    const [whatsAppNumber, setWhatsAppNumber] = useState(RowData?.whatsapp_number)
    const [whatsAppUpdateLoading, setWhatsAppUpdateLoading] = useState(false)

    const handleWhatsAppUpdateClose = () => {
        setWhatsAppNumberUpdateShow(false)
    }

    const handleWhatsAppUpdate = async (event: any) => {
        event.preventDefault()
        setWhatsAppUpdateLoading(true)
        const response: any = await postRequest(URL_EndPoints(null)?.updateChampWhatsAppNumber, {
            champId: ChampDetail?.id,
            number: whatsAppNumber,
        }, setWhatsAppUpdateLoading)
        if (response?.data?.status === 200) {
            ChampDetail.whatsapp_number = whatsAppNumber
            setRowData(ChampDetail)
            setWhatsAppNumberUpdateShow(false)
            toast.success(response.data.message)
        } else {
            toast.info(response.data.message)
        }
        setWhatsAppUpdateLoading(false)
    }


    const [buddyData, setBuddyData] = useState<any>([])
    const [defaultBuddyData, setDefaultBuddyData] = useState<any>(null)
    const getChampbuddyActiveSupervisor = async () => {
        const buddyData = await getRequest(URL_EndPoints(adminQueryGenerator("getAreaManagerActiveChamps", { champId: ChampDetail?.id }))?.getAreaManagerActiveChamps, null)
        setBuddyData(buddyData?.data?.data)
    }

    useEffect(() => {
        getChampbuddyActiveSupervisor()
    }, [])

    const subAreaPrevData4subAreaForm = ChampDetail?.cleaner_sub_area_details ? { label: ChampDetail?.cleaner_sub_area_details?.name, value: ChampDetail?.cleaner_sub_area_details?.id } : null
    const champAvailibility = ChampDetail?.champAvailabilities?.length > 0 ? ChampDetail?.champAvailabilities?.map((ele: any) => ({ label: ele?.champAvailabilityTimeslot?.name, value: ele?.champAvailabilityTimeslot?.id })) : null
    const champFresherBuddyPreferences = ChampDetail?.fresherBuddyPreferences?.length > 0 ? ChampDetail?.fresherBuddyPreferences?.map((ele: any) => ele?.buddy_user_id) : null

    useEffect(() => {
        const filterBuddyData = buddyData?.filter((ele: any) => champFresherBuddyPreferences?.includes(ele?.id))?.map((ele: any) => ({ label: ele?.first_name + " " + ele?.last_name, value: ele?.id }))
        buddyData?.length > 0 && setDefaultBuddyData(filterBuddyData)
    }, [buddyData])

    const setUpdatedSubAreaName = (newSubArea: any) => {
        if (newSubArea?.isSet) {
            ChampDetail.cleaner_sub_area_details = ChampDetail.cleaner_sub_area_details?.name ? { ...ChampDetail.cleaner_sub_area_details } : {}
            ChampDetail.cleaner_sub_area_details.name = newSubArea?.payload?.sub_area_id_item?.item?.name
            setRowData(ChampDetail)
        }
        // 👉 keyName+"_"+"item" == for subArea update ==> sub_area_id_item?.item?.name
    }

    const setUpdatedChampAvailibilty = ({ isSet, payload }: any) => {
        let { timeslot_ids, filterDataList } = payload
        if (isSet) {
            const sortedIds = timeslot_ids?.sort((a: any, b: any) => a - b)
            const timesloteRange: any = filterDataList?.getAvailabilityTimeslots?.filter((ele: any) => sortedIds?.includes(ele?.id))?.map((ele: any) => ({ ...ele, champAvailabilityTimeslot: { name: ele?.name, id: ele?.id } }))
            const timesloteRangeStr = timesloteRange?.map((ele: any) => ele?.name)
            const timeSloteNames = timesloteRangeStr?.length > 0 ? timesloteRangeStr[0]?.split("-")[0] + "-" + timesloteRangeStr[timesloteRangeStr?.length - 1]?.split("-")[1] : null
            if (timeSloteNames) {
                ChampDetail.champ_availability = timeSloteNames
                ChampDetail.champAvailabilities = timesloteRange
                setRowData(ChampDetail)
            }
        }
    }

    const setUpdatedChampFresherBuddyPreferences = ({ isSet, payload }: any) => {
        let { buddyIds, filterDataList } = payload
        if (isSet) {
            const currentUpdatedPreference: any = filterDataList?.getAreaManagerActiveChamps?.filter((ele: any) => buddyIds?.includes(ele?.id))?.map((ele: any) => ({ label: ele?.first_name + " " + ele?.last_name, value: ele?.id }))
            currentUpdatedPreference?.length > 0 && setDefaultBuddyData(currentUpdatedPreference)
        }
    }


    let renderConditons: any = {

        champReferralRequest: {
            name: RowData?.champReferralRequest ? "Paid" : "UnPaid",
            style: RowData?.champReferralRequest ? "success" : "danger"
        },

        statusIsDeleted: {
            name: RowData?.status == 4 && RowData?.isdeleted > 1 ? "Inactive" :
                RowData?.status == 4 && RowData?.isdeleted > 0 ? "Active"
                    : "Not Activated",
            style: RowData?.status == 4 && RowData?.isdeleted > 1 ? "danger" :
                RowData?.status == 4 && RowData?.isdeleted > 0 ? "success"
                    : "danger"
        },
        accountDeleted: {
            name: RowData?.accountDeleted == 0 ? "Not Blocked" : RowData?.accountDeleted == 1 ? "Blocked" : "Not Set",
            style: RowData?.accountDeleted == 0 ? "success" : RowData?.accountDeleted == 1 ? "danger" : "danger"
        },
        cleanerReferBy: {
            name: RowData?.cleanerReferBy?.first_name || RowData?.cleanerReferBy?.last_name ? RowData?.cleanerReferBy?.first_name + " " + RowData?.cleanerReferBy?.last_name : "NA",
            style: RowData?.cleanerReferBy?.first_name || RowData?.cleanerReferBy?.last_name ? "success" : "danger"
        },
        is_blocked: {
            name: RowData?.is_blocked == 0 ? "No" : "Yes",
            style: RowData?.is_blocked == 0 ? "success" : "danger"
        },
        new_champ_unverified: {
            name: RowData?.new_champ_unverified == 2 ? "No" : "Yes",
            style: RowData?.new_champ_unverified == 2 ? "danger" : "success"
        },

    }

    const setUpdatedIsBlocked = () => {
        ChampDetail.is_blocked = ChampDetail.is_blocked == 0 ? 1 : 0
        setRowData(ChampDetail)
    }

    const setUpdatedIsVisibleInChampLeads = () => {
        ChampDetail.new_champ_unverified = ChampDetail.new_champ_unverified == 2 ? 0 : 2
        setRowData(ChampDetail)
    }

    function callback(value: any) {
        if (isAllowedCopyNumber()) {
            copy(value)
            toast.success(`Copied to clipboard - ( ${value} ) `, { position: "top-center" })
        }
    }

    const copyToClipboards = React.useCallback(callback, [callback])

    return (
        <div >


            {
                <div className="px-4 mt-3 p-3 w-100">
                    <div className=' d-flex flex-wrap align-items-start bg  rounded p-5 mr-4' >

                        <div className='flex-grow-1 me-2'>

                            {/* <p id='id' className='mr-1 ml-4 cursor-pointer me-2' 
                                onClick={() => copyToClipboards(`${RowData.first_name} ${RowData.last_name}, ${RowData.id}`)} >
                                Name: {RowData?.first_name} {RowData?.last_name} 
                                
                                <ToolTips toolTipId="id" ToolTipShowData="Click to copy champ name and id" />
                                
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-muted  cursor-pointer" onClick={() => setOpenModal(true)}></i>
                            </p> */}

                            <span
                                id="name"
                                className="mr-1 ml-4 cursor-pointer me-2"
                                style={{
                                    display: 'inline-block',
                                    marginBottom: '8px',
                                    position: 'relative'
                                }}
                                onClick={() => copyToClipboards(`${RowData.first_name} ${RowData.last_name}, ${RowData.id}`)}>

                                Name : {RowData?.first_name} {RowData?.last_name}

                                <ToolTips toolTipId="name" ToolTipShowData="Click to copy customer name and id"
                                    style={{ position: 'absolute', top: '100%', left: '0' }} />

                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-muted  cursor-pointer" onClick={() => setOpenModal(true)}></i>

                            </span>

                            <p>
                                <span className='me-1'> Email: {RowData?.email ? RowData?.email : "NA"} </span>
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-muted  cursor-pointer" onClick={() => setOpenModal2(true)}></i>
                            </p>

                            <p>
                                <span className='me-1'> Phone: {isAllowedChampNumber(<MaskedPhoneNumber phoneNumber={RowData?.phone} />, "🚫🚫🚫")}</span>
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-muted  cursor-pointer" onClick={() => setPhoneNumberUpdateShow(true)}></i>
                            </p>

                            <p>
                                <span className='me-1'> WhatsApp: {isAllowedChampNumber(<MaskedPhoneNumber phoneNumber={RowData?.whatsapp_number} />, "🚫🚫🚫")}</span>
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-muted  cursor-pointer" onClick={() => setWhatsAppNumberUpdateShow(true)}></i>
                            </p>

                            <p>
                                Champ ID : {RowData?.id || "Not Set"}
                            </p>

                            <p>
                                City : {RowData?.mc ? RowData?.mc?.city_name : "NA"}
                            </p>

                            <p>
                                Rating : {RowData?.rating.toFixed(1)}   {/* <RatingStars rating={parseFloat(3.5.toFixed(1))} /> */}
                            </p>
                            <p>
                                App Version :   <InformationChip SelectedString={RowData?.champMetaDataSingle?.buildversion} classes={"dark"} Id={"champ"} toolTipName="Champ App Version" ></InformationChip>
                            </p>

                            {/* <p>
                                Supervisor Name : {ChampDetail?.cleanerSupervisor?.length > 0 ? ChampDetail?.cleanerSupervisor[0]?.supervisorcleaner?.first_name + " " + ChampDetail?.cleanerSupervisor[0]?.supervisorcleaner?.last_name : "NA"}
                            </p> */}

                            <ChampRowDashboardAction
                                title="Supervisor Name"
                                icon={faEdit}
                                setShow={null}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                {ChampDetail?.cleanerSupervisor?.length > 0 ? ChampDetail?.cleanerSupervisor[0]?.supervisorcleaner?.first_name + " " + ChampDetail?.cleanerSupervisor[0]?.supervisorcleaner?.last_name : "NA"}
                            </ChampRowDashboardAction>
                            {/* <p>
                                Is Active: {RowData?.status == 4 && RowData?.isdeleted > 0 ? <span className="badge badge-success">Active</span> : RowData?.status == 4 && RowData?.isdeleted > 1 ? <span className="badge badge-danger">Inactive</span> : <span className="badge badge-danger">Not Activated</span>}
                            </p> */}
                            <ChampRowDashboardAction
                                title=" Is Active"
                                icon={faEdit}
                                setShow={null}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                <span className={`badge badge-light-${renderConditons["statusIsDeleted"]?.style} text-dark me-3`}>
                                    {renderConditons["statusIsDeleted"]?.name
                                    }
                                </span>
                            </ChampRowDashboardAction>

                            {/* <p>
                                Is Active Champ: {RowData?.status == 4 && RowData?.isdeleted == 0 ? <span className="badge badge-success">De-Activate</span> : RowData?.status == 4 && RowData?.isdeleted == 1 ? <span className="badge badge-danger">Re-Activate</span> : <span className="text-dark">Prospect</span>}
                            </p> */}

                            <ChampRowDashboardAction
                                title="Is Active Champ"
                                icon={faEdit}
                                setShow={null}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                <span className={`badge badge-light-${"success"} text-dark me-3`}>
                                    {
                                        RowData?.status == 4 && RowData?.isdeleted == 0 ? "De-Activate" : RowData?.status == 4 && RowData?.isdeleted == 1 ? "Re-Activate" : "Prospect"
                                    }
                                </span>
                            </ChampRowDashboardAction>

                            {/* <p>
                                <span className='me-1'> Is Block: </span>
                                {RowData?.accountDeleted == 0 ? <span className="badge badge-success">Not Blocked</span> : RowData?.accountDeleted == 1 ? <span className="badge badge-danger">Blocked</span> : "Not Set"}
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => blockunblockHandler()}></i>
                            </p> */}

                            <ChampRowDashboardAction
                                title="Is Block"
                                icon={faEdit}
                                setShow={blockunblockHandler}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                <span className={`badge badge-light-${renderConditons["accountDeleted"]?.style} text-dark me-3`}>{
                                    renderConditons["accountDeleted"]?.name
                                }</span>
                            </ChampRowDashboardAction>


                            <ChampRowDashboardAction
                                title="Is Login Blocked"
                                icon={faEdit}
                                setShow={setIsLoginBlocked}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                                randomValue={true}
                            >
                                <span className={`badge badge-light-${renderConditons["is_blocked"]?.style} text-dark me-3`}>{
                                    renderConditons["is_blocked"]?.name
                                }</span>
                            </ChampRowDashboardAction>


                            <ChampRowDashboardAction
                                title="Visible in champ leads"
                                icon={faEdit}
                                setShow={setIsVisibleInChampLeads}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                                randomValue={true}
                            >
                                <span className={`badge badge-light-${renderConditons["new_champ_unverified"]?.style} text-dark me-3`}>{
                                    renderConditons["new_champ_unverified"]?.name
                                }</span>
                            </ChampRowDashboardAction>

                            {/* <p>
                                <span className='me-1'> Refer By: </span>
                                {RowData?.cleanerReferBy?.first_name || RowData?.cleanerReferBy?.last_name ? <span className="badge badge-success">{RowData?.cleanerReferBy?.first_name + " " + RowData?.cleanerReferBy?.last_name}</span> : <span className="badge badge-danger">NA</span>}
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => referByHandler()}></i>
                            </p> */}

                            <ChampRowDashboardAction
                                title="Refer By"
                                icon={faEdit}
                                setShow={referByHandler}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                <span className={`badge badge-light-${renderConditons["cleanerReferBy"]?.style} text-dark me-3`}>{renderConditons["cleanerReferBy"]?.name}</span>
                            </ChampRowDashboardAction>


                            {/* <p>
                                <span className='me-1'> Is Paid: </span>
                                {RowData?.champReferralRequest ? <span className="badge badge-success">Paid</span> : <span className="badge badge-danger">UnPaid</span>}
                                <i className="fas fa-edit ms-2 ms-2 fs-5 text-success  cursor-pointer" onClick={() => isPaidHandler()}></i>
                            </p> */}

                            <ChampRowDashboardAction
                                title="Is Paid"
                                icon={faEdit}
                                setShow={isPaidHandler}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                <span className={`badge badge-light-${renderConditons["champReferralRequest"]?.style} text-dark me-3`}>{renderConditons["champReferralRequest"]?.name}</span>
                            </ChampRowDashboardAction>

                            <div className='d-flex mb-3'>
                                Private Tags:
                                <div className='d-flex'>
                                    {
                                        RowData?.private_tag === "" || RowData?.private_tag == null ? <span className='me-1 '> <span className={`ms-2 badge badge-light-${"danger"} text-dark me-2`}> No Tags Available</span> <i className="fas fa-edit fs-4 text-success  cursor-pointer" onClick={() => handleEditTags("Private Tags")}></i></span>
                                            :
                                            RowData?.private_tag.split(",").map((ele: any, index: number, arr: any) => {
                                                if (index == arr?.length - 1) return (
                                                    <div className='d-flex align-items-center'>
                                                        <span className={`ms-2 badge badge-light-${"success"} text-dark me-2`}>{ele}</span>
                                                        <span className="me-1 fw-bold">
                                                            <i className="fas fa-edit text-muted fs-4 cursor-pointer" onClick={() => handleEditTags("Private Tags")}></i>
                                                        </span>
                                                    </div>
                                                )
                                                else return (
                                                    <span className="me-1  badge badge-secondary me-2 text-dark">{ele}</span>
                                                )
                                            })
                                    }
                                </div>
                            </div>

                            <div className='d-flex mb-2'>
                                Public Tags:
                                <div className='d-flex ms-1'>
                                    {
                                        RowData?.public_tag === "" || RowData?.public_tag == null ? <span className='me-1 '> <span className={`badge badge-light-${"danger"} text-dark me-2`}> No Tags Available</span> <i className="fas fa-edit fs-4 text-muted  cursor-pointer" onClick={() => handleEditTags("Public Tags")}></i></span>
                                            :
                                            RowData?.public_tag.split(",").map((ele: any, index: number, arr: any) => {
                                                if (index == arr?.length - 1) return (
                                                    <div className='d-flex'>
                                                        <span className={`badge badge-light-${"success"} text-dark me-2`}>{ele}</span>
                                                        <span className="me-1  fs-8 fw-bold">
                                                            <i className="fas fa-edit text-muted fs-4 cursor-pointer" onClick={() => handleEditTags("Public Tags")}></i>
                                                        </span>
                                                    </div>
                                                )
                                                else return (
                                                    <span className="me-1 badge badge-secondary me-2 text-dark">{ele}</span>
                                                )
                                            })
                                    }
                                </div>
                            </div>

                            {/* <div className='d-flex g-1 mb-2'>
                                Add Area Name:
                                {
                                    RowData?.cleanerAreas.map((ele: any) => {
                                        return <span className='me-2 ms-2 fw-bolder badge badge-light-dark fs-8  '>
                                            <span className='me-2 cursor-pointer' onClick={() => updateAreaHandler(ele)}> {ele?.areaDetail?.areaname}</span>
                                            <i className="bi bi-trash ms-3 fs-5 mt-1 text-dark  cursor-pointer" onClick={() => deleteAreaHandler(ele)}></i>
                                        </span>
                                    })
                                }

                                <i className="ms-2 bi bi-plus-square text-success  fs-4 mt-1 cursor-pointer " onClick={() => setAddAreaModal(true)}></i>
                            </div> */}

                            <ChampRowDashboardAction
                                title="Add Area Name"
                                icon={faEdit}
                                setShow={setAddAreaModal}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                {
                                    RowData?.cleanerAreas.map((ele: any) => {
                                        return <span className={`badge badge-light-${"success"} text-dark me-3`}>
                                            <span className='me-2 cursor-pointer' onClick={() => updateAreaHandler(ele)}>
                                                {ele?.areaDetail?.areaname}
                                            </span>
                                            <FontAwesomeIcon icon={faTrashAlt} className='cursor-pointer fs-7 ms-2' onClick={() => deleteAreaHandler(ele)} />
                                        </span>
                                    })
                                }

                            </ChampRowDashboardAction>

                            {/* <p>
                                <span className='me-3'> Add Champ Requirement: </span>
                                {RowData?.champ_requirement_details?.requirementType && <span className="badge badge-success">{RowData?.champ_requirement_details?.requirementType?.name}</span>}
                                <i className="fas fa-edit ms-2 ms-2 fs-4 text-success  cursor-pointer" onClick={() => champRequirementHandler()}></i>
                            </p>
                             */}
                            <ChampRowDashboardAction
                                title="Add Champ Requirement"
                                icon={faEdit}
                                setShow={champRequirementHandler}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                {RowData?.champ_requirement_details?.requirementType && <span className={`badge badge-light-${"success"} text-dark me-3`}>{RowData?.champ_requirement_details?.requirementType?.name}</span>}

                            </ChampRowDashboardAction>

                            {/* <p>
                                <span className='me-3'> Add Sub Area: </span>
                                {RowData?.cleaner_sub_area_details?.name && <span className="badge badge-success">{RowData?.cleaner_sub_area_details?.name}</span>}
                                <i className="fas fa-edit ms-2 ms-2 fs-4 text-success  cursor-pointer" onClick={() => setShowSubArea(true)}></i>
                            </p> */}
                            {/* badge badge-light-${isAmountTransferred} text-dark me-3 */}

                            <ChampRowDashboardAction
                                title="Add Sub Area"
                                icon={faEdit}
                                setShow={setShowSubArea}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                {RowData?.cleaner_sub_area_details?.name && <span className={`badge badge-light-${"success"} text-dark me-3`}>{RowData?.cleaner_sub_area_details?.name}</span>}
                            </ChampRowDashboardAction>


                            {/* <p>
                                <span className='me-3'> Champ Availability: </span>
                                <span className="badge badge-secondary text-dark">{RowData?.champ_availability ? RowData?.champ_availability : "NA"}</span> <FontAwesomeIcon icon={faEdit} className=' fs-4 text-muted' onClick={() => setShowAvailibilty(true)} />
                            </p> */}

                            <ChampRowDashboardAction
                                title=" Champ Availability"
                                icon={faEdit}
                                setShow={setShowAvailibilty}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                <span className={`badge badge-light-${"success"} text-dark me-3`}>{RowData?.champ_availability ? RowData?.champ_availability : "NA"}</span>
                            </ChampRowDashboardAction>


                            <ChampRowDashboardAction
                                title="Champ Buddy"
                                icon={faEdit}
                                setShow={setShowBuddy}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                {
                                    defaultBuddyData?.map((ele: any) => <span className={`badge badge-light-${"success"} text-dark me-3`}>{ele?.label}</span>)
                                }
                                {
                                    defaultBuddyData?.length === 0 && <span className='badge badge-light-danger text-dark me-2'>Not Set</span>
                                }
                            </ChampRowDashboardAction>
                            <ChampRowDashboardAction
                                title="Notice Period Date"
                                icon={faEdit}
                                setShow={null}
                                classes={`me-3`}
                                iconCss={`fs-4 text-muted`}
                            >
                                {
                                    RowData?.notice_period_date ? RowData?.notice_period_date : "NA"
                                }
                            </ChampRowDashboardAction>

                            {
                                addAreaModal && <AddAreaName
                                    show={addAreaModal}
                                    setCloseModal={setAddAreaModal}
                                    row={RowData}
                                    AreaList={AreaList}
                                    cleanerListData={cleanerListData}
                                />
                            }

                            {
                                updateAreaModal && <UpdateAreaNameList
                                    show={updateAreaModal}
                                    setCloseModal={setUpdateAreaModal}
                                    row={RowData}
                                    AreaList={AreaList}
                                    updateAreaDetails={updateAreaDetails}
                                />
                            }

                            {
                                deleteAreaModal ? <DeleteAreaName
                                    confirm='Yes'
                                    cancel='No'
                                    render='No'
                                    successTitle='Changes Are Saved Successfully '
                                    FailedTitle='Changes Cancelled'
                                    payload={{
                                        id: deleteAreaDetails?.id,
                                        champId: RowData?.id,
                                        areaId: deleteAreaDetails?.area_id,
                                        status: 0
                                    }}
                                    setDeleteArea={setDeleteArea}
                                />
                                    : <></>
                            }

                            {
                                show && selectedTag == "Private Tags" ? <AddPrivatetTag handleCloseForm={closeBtn} show={show} row={ChampDetail} setRowData={setRowData} states={states} /> : show && selectedTag == "Public Tags" ? <AddPublicTag handleCloseForm={closeBtn} show={show} row={ChampDetail} setRowData={setRowData} states={states} /> : ""
                            }

                            {
                                PhoneNumberUpdateShow ? <ChampPhoneNumberUpdate FilteredData={{
                                    disable,
                                    loader,
                                    setPhoneNumber,
                                    phoneNumber,
                                    handleClose: closeBtn,
                                    PhoneNummberUpdate,
                                    PhoneNumberUpdateShow,
                                }} handleCloseForm={closeBtn}></ChampPhoneNumberUpdate>
                                    : <></>
                            }

                            <WhatsAppNumberUpdate FilteredData={{
                                setRowData,
                                whatsAppNumberUpdateShow,
                                handleWhatsAppUpdateClose,
                                setWhatsAppNumber,
                                whatsAppNumber,
                                whatsAppUpdateLoading,
                                handleWhatsAppUpdate,
                            }} />


                            {openModal ? <ChampNameUpdate
                                champDetails={ChampDetail}
                                openModal={openModal}
                                setRowData={setRowData}
                                handleCloseForm={closeBtn}
                            ></ChampNameUpdate>
                                : <></>

                            }

                            {openModal2 ? <ChampEmailUpdate
                                champDetails={ChampDetail}
                                openModal={openModal2}
                                setRowData={setRowData}
                                handleCloseForm={closeBtn}
                            ></ChampEmailUpdate>
                                : <></>
                            }
                        </div>
                    </div>

                </div>
            }

            {/* {
                ShowBlockForm ?
                    <IsBlockAlertChamp render={Renders} confirm={'Yes'} cancel={'No'} payloads={{
                        champId: ChampDetail?.id,
                        isblock: ChampDetail?.accountDeleted,
                    }}
                        setRowData={setRowData}
                        ChampDetail={ChampDetail}
                        setShowBlockForm={setShowBlockForm}
                        title={`Are you sure want to ${ChampDetail?.accountDeleted == 0 ? "Block" : "Unblock"} ?`} /> : ""
            } */}

            {
                ShowBlockForm ? <div key={ChampDetail?.id + "_" + ChampDetail?.accountDeleted}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={`Are you sure want to ${ChampDetail?.accountDeleted == 0 ? "Block" : "Unblock"} ?`}
                        FailedTitle='Changes Cancelled '
                        payload={{
                            champId: ChampDetail?.id,
                            isblock: ChampDetail?.accountDeleted === 1 ? 0 : 1,
                        }}
                        show={ShowBlockForm}
                        setShow={setShowBlockForm}
                        setUpdatePrevState={setUpdatePrevStateBlock}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="blockUnblockChamp"
                        row={ChampDetail}

                    />
                </div> : <></>
            }

            {/* {
                showIsPaidModal ?
                    <IsPaidChampAlert render={renderIsPaid} confirm={'Yes'} cancel={'No'} payloads={{
                        champ_id: ChampDetail?.id,
                    }}
                        setRowData={setRowData}
                        ChampDetail={ChampDetail}
                        showIsPaidModal={showIsPaidModal}
                        title={`Are you sure want to ${!ChampDetail?.champReferralRequest && "Paid"} ?`} /> : ""
            } */}


            {
                showIsPaidModal ?
                    <IsPaidChampStatus
                        setRowData={setRowData}
                        row={ChampDetail}
                        show={showIsPaidModal}
                        handleCloseForm={closeBtn}

                    /> : <></>
            }
            {
                showReferModal ?
                    <UpdateReferByChamp
                        setRowData={setRowData}
                        row={ChampDetail}
                        show={showReferModal}
                        handleCloseForm={closeBtn}

                    /> : <></>
            }
            {
                showRequirementTypeModal ?
                    <AddChampRequirementType
                        setRowData={setRowData}
                        row={ChampDetail}
                        show={showRequirementTypeModal}
                        handleCloseForm={closeBtn}

                    /> : <></>
            }


            {/* {
                showSubArea ? <SubAreaAssignForm show={showSubArea} setShow={setShowSubArea} row={ChampDetail} setRowData={setRowData} /> : <></>

            } */}

            {
                showSubArea ? <AdminChampActionForm
                    show={showSubArea}
                    setShow={setShowSubArea}
                    setStoreRow={setUpdatedSubAreaName}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={champDashboardSubAreaActionFormData}
                    staticFormData={[]}
                    initialData={{
                        champId: [ChampDetail?.id],
                        sub_area_id: subAreaPrevData4subAreaForm?.value ? subAreaPrevData4subAreaForm?.value : null,
                    }}
                    prevInitialData={{ item: subAreaPrevData4subAreaForm }}
                    keyName="subAreaAddOrUpdateForChamp1"
                    formTitle={`Update Sub Area`}
                    formSubmitApiUrl="addCleanerAddressInBulk"
                /> : <></>
            }

            {
                showAvailibility ? <AdminChampActionForm
                    show={showAvailibility}
                    setShow={setShowAvailibilty}
                    setStoreRow={setUpdatedChampAvailibilty}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={champAvailibilityFormData}
                    staticFormData={[]}
                    initialData={{
                        cleanerid: ChampDetail?.id,
                        timeslot_ids: champAvailibility ? champAvailibility?.map((ele: any) => ele?.value) : null,
                    }}
                    prevInitialData={{ item: champAvailibility }}
                    keyName="updateChampAvailability"
                    formTitle={`Update Champ Availability`}
                    formSubmitApiUrl="addCleanerAvailabilities"
                /> : <></>
            }

            {
                showBuddy ? <AdminChampActionForm
                    show={showBuddy}
                    setShow={setShowBuddy}
                    setStoreRow={setUpdatedChampFresherBuddyPreferences}
                    refreshNow={() => { }}
                    toastMessage="msg"
                    formData={champBuddyFormData}
                    staticFormData={[]}
                    dynamicApiData={{
                        getAreaManagerActiveChamps: {
                            url: 'getAreaManagerActiveChamps',
                            method: 'GET',
                            isQuery: `champId=${ChampDetail?.id}`,
                            isParams: null,
                            isBody: null,
                        }
                    }}
                    initialData={{
                        champId: ChampDetail?.id,
                        buddyIds: defaultBuddyData ? defaultBuddyData?.map((ele: any) => ele?.value) : null,
                    }}
                    prevInitialData={{ item: defaultBuddyData }}
                    keyName="updateBuddyForm"
                    formTitle={`Update Champ Buddy`}
                    formSubmitApiUrl="addBuddyPreferences"
                /> : <></>
            }

            {
                isLoginBlocked ? <div key={ChampDetail?.id + "_" + ChampDetail?.id}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={`Are you sure want to ${ChampDetail?.is_blocked == 0 ? "Block" : "Unblock"} ?`}
                        FailedTitle='Changes Cancelled '
                        payload={{
                            champID: ChampDetail?.id,
                        }}
                        show={isLoginBlocked}
                        setShow={setIsLoginBlocked}
                        setUpdatePrevState={setUpdatedIsBlocked}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="toggleLoginBlocked"
                        row={ChampDetail}
                    />
                </div> : <></>
            }

            {
                isVisibleInChampLeads ? <div key={ChampDetail?.id + "_" + ChampDetail?.id}>
                    <AdminYesNoAlertAction
                        confirm='Yes'
                        cancel='No'
                        successTitle={`Are you sure want to ${ChampDetail?.new_champ_unverified == 2 ? "Show in Champ Leads" : "Hide from Champ Leads"} ?`}
                        FailedTitle='Changes Cancelled'
                        payload={{
                            champID: ChampDetail?.id,
                        }}
                        show={isVisibleInChampLeads}
                        setShow={setIsVisibleInChampLeads}
                        setUpdatePrevState={setUpdatedIsVisibleInChampLeads}
                        refreshNow={null}
                        setPending={null}
                        urlEndPoint="toggleVisibleInChampLeads"
                        row={ChampDetail}
                    />
                </div> : <></>
            }

        </div >
    )
}
// { customerid, isblock }
export default ChampProfile
