import OpenChat from "../../../consts/champChatDashboard/OpenChat"

import { getFullName } from "../../../utils/helper.util"
import { getName } from "../../admins/adminUtils"

const ChampDetailTable = ({ rowData, submitForm }: any) => {
    const availableTimeslots = ["06AM-07AM", "07AM-08AM", "08AM-09AM", "09AM-10AM", "10AM-11AM", "11AM-12PM"]
    const btnStyle = {
        height: "1.30rem",
        display: "flex",
        alignItems: "center",
    }
    return <div className="table-responsive border border-2 rounded p-3" style={{ maxHeight: "15rem" }}>
        <table className="table table-striped table-hover w-100">
            <thead className="position-sticky" style={{ backgroundColor: "#f9fafc", top: '-10px' }}>
                <tr>
                    <th scope="col" className="text-center">Champ</th>
                    <th scope="col" className="text-center">Distance</th>
                    <th scope="col" className="text-center">Workload</th>
                    <th scope="col" className="text-center">Same Society</th>
                    <th scope="col" className="text-center">Repeated Champ</th>
                    {availableTimeslots?.map((timeslot: any) => (
                        <th scope="col" className="text-center">{timeslot}</th>
                    ))}
                </tr>
            </thead>
            <tbody >
                {rowData?.inRangeChamps?.length == 0 && <tr>
                    <td colSpan={availableTimeslots?.length + 5} className="text-center">No Champ Available</td>
                </tr>}
                {rowData?.inRangeChamps?.map((champ: any) => {
                    return <tr>
                        <td className="d-flex align-items-center">
                            {
                                rowData?.availableChamp?.id == champ?.id ? (
                                    <button
                                        className='btn btn-sm btn-success'
                                        onClick={() => submitForm({
                                            jobId: rowData?.id,
                                            champId: rowData?.availableChamp?.id,
                                            assignment_type: 1
                                        })}
                                        style={btnStyle}
                                    >
                                        Assign
                                    </button>
                                ) : (
                                    <button 
                                        className="btn btn-sm btn-primary"
                                        style={btnStyle}
                                        onClick={() => submitForm({
                                            jobId: rowData?.id,
                                            champId: champ?.id,
                                            assignment_type: 2
                                        })}
                                    >
                                        Assign
                                    </button>
                                )
                            }
                            <OpenChat
                                title={getFullName(champ)}
                                champId={champ?.id}
                            />
                            <span className={`badge badge-light-info`}>{getName(champ?.champSupervisor)}</span>
                        </td>
                        <td className={`text-center ${champ?.distanceinnumber <= 1 ? "text-success" : ""}`}>{champ?.distanceinnumber}KM</td>
                        <td className={`text-center ${champ?.loadPercentage <= 60 ? "text-success" : ""}`}>{champ?.loadPercentage}%</td>
                        <td className={`text-center ${champ?.availableInSociety ? "text-success" : ""}`}>{champ?.availableInSociety ? "Yes" : "No"}</td>
                        <td className={`text-center ${champ?.isRepeatChamp ? "text-success" : ""}`}>{champ?.isRepeatChamp ? "Yes" : "No"}</td>
                        {availableTimeslots?.map((timeslot: any) => {
                            const timeslotJobs = champ?.timeslotJobs?.[timeslot]
                            const totalPoints = timeslotJobs?.reduce((acc: any, curr: any) => acc + curr?.point, 0)
                            const isAvailable = totalPoints > 0
                            const isOverLoaded = totalPoints > 4
                            const timeslotAvailable = champ?.champAvailabilities?.find((availability: any) => availability?.champAvailabilityTimeslot?.name == timeslot) ? true : false
                            return <td className="text-center">
                                {<div
                                    className={`badge badge-light-${timeslotAvailable ? (isAvailable ? (isOverLoaded ? "danger" : "success") : "info") : "warning"} fw-bolder mb-2 rounded d-flex justify-content-center text-align`}
                                    style={{ whiteSpace: 'pre-wrap', cursor: "pointer" }}
                                >
                                    {timeslotAvailable ? (isAvailable ? totalPoints : "NJ") : "NA"}
                                </div>}
                            </td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}
export default ChampDetailTable