import React from 'react';
import { withSwal as AdminMultipleYesNoCancel } from 'react-sweetalert2';
import { postRequest } from '../../../consts/Server/Requests';
import { URL_EndPoints } from '../../../consts/Server/URL_EndPoints';
import { adminToastRunner } from '../adminUtils';


export default AdminMultipleYesNoCancel((props: any) => {
    const { swal, confirm, cancel, payload, updateNewPayload, successTitle, FailedTitle, setShow = null, show = false, setUpdatePrevState = null, refreshNow = null, setPending = null, row = {}, urlEndPoint, }: any = props;

    async function sendRequestToServer(payload: any) {
        setPending && setPending(true)
        const response = await postRequest(URL_EndPoints(null)?.[urlEndPoint], payload, setPending)
        adminToastRunner(response)
        if (response?.data?.status == 200) {
            setUpdatePrevState && setUpdatePrevState(payload, row)
            refreshNow && refreshNow()
        }
        setShow && setShow(null)
    }

    React.useEffect(() => {
        if (show) {
            swal.fire({
                title: successTitle,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: confirm,
                denyButtonText: cancel
            }).then((result: any) => {
                if (result.isConfirmed) {
                    sendRequestToServer(updateNewPayload(payload, true))
                }
                else if (result.isDenied) {
                    sendRequestToServer(updateNewPayload(payload, false))
                }
                else if (result.isDismissed) {
                    setShow(null)
                    swal.fire(FailedTitle)
                }


            })
        }
    }, [show])
});

// const setUpdatePrevState = (updatedState: any, row:any) => {
//     data.pinned = data?.pinned == 1 ? 0 : 1
//     // setStoreRowData(data)
// }


// {
//     openUpdateRequirementLead ? <div key="champhiringautomation">
//         <AdminMultipleYesNoCancel
//             confirm='Yes'
//             cancel='No'
//             successTitle={`Do you want to delete previous records ?`}
//             FailedTitle='Changes Cancelled '
//             payload={{
//                 delete_prev: false
//             }}
//             updateNewPayload={(payload: any, action: any) => {
//                 let newPayload = JSON.parse(JSON.stringify(payload))
//                 if (action) {
//                     newPayload.delete_prev = true
//                 }
//                 else {
//                     newPayload.delete_prev = false
//                 }
//             }}
//             show={openUpdateRequirementLead}
//             setShow={setOpenUpdateRequirementLead}
//             setUpdatePrevState={null}
//             refreshNow={refreshNow}
//             setPending={null}
//             urlEndPoint="updateRequirementLeadsCountXXXX"
//             row={null}

//         />
//     </div> : <></>
// }