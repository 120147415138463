import React, {FC, useEffect, useState} from 'react'
import SingleSelectSearchDetailsDefaultDyanmic from '../../consts/Select/SingleSelectSearchDetailsDefaultDyanmic'
import AdminMultiSelectFilter from '../admins/adminForms/adminFormComponents/AdminMultiSelectFilter'
const RenewalDataFilter = ({ParentData}: any) => {
  let from = 'startMonth'
  let to = 'endMonth'
  return (
    <div className='p-6'>
      <h3 className='fw-bold text-dark mb-8'>Filteration</h3>
      <div className='row'>
          <div>
              <span>Start Date</span>
              <input
                type='date'
                style={{height: '38px'}}
                value={ParentData?.payloads[from]}
                name={from}
                onChange={(event: any) => ParentData?.onChangeHandler(event.target, from, true)}
                className='w-100 form-control me-2 align-start'
              />
          </div>
          <div>
            <span>End Date</span>
            <input
              type='date'
              style={{height: '38px'}}
              name={to}
              value={ParentData?.payloads[to]}
              onChange={(event: any) => ParentData?.onChangeHandler(event.target, to, true)}
              className='w-100 form-control me-2 align-start'
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>Data Type</span>
            <SingleSelectSearchDetailsDefaultDyanmic
              handleChangeInputData={ParentData?.onChangeHandler}
              HeaderTitle='Select Data Type'
              SelectData={ParentData?.dataTypes || []}
              DynamicKeys={["name"]}
              id={"id"}
              name={"dataType"}
              targetValue={"value"}
              defaultData={ParentData?.defaultDataType}
            ></SingleSelectSearchDetailsDefaultDyanmic>
          </div>
          
          <div>
            <span className='fs-semibold fs-7'>Onboarded By</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Onboarded By"
              SelectData={ParentData?.onboardedBy || []}
              displayFields={["first_name", "last_name", "name"]}
              id="id"
              name="onboardedBy"
              defaultData={ParentData?.defaultOnboardedBy}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
              blankForNA={true}
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>Frequency</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Frequency"
              SelectData={ParentData?.frequencies || []}
              displayFields={["name"]}
              id="id"
              name="frequencyid"
              defaultData={ParentData?.defaultFrequency}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>Autopay</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Autopay"
              SelectData={ParentData?.autopays || []}
              displayFields={["name"]}
              id="id"
              name="isAutopay"
              defaultData={ParentData?.defaultAutopay}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>No. of Months</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle='Select No. of Months'
              SelectData={ParentData?.noOfMonths || []}
              displayFields={["name"]}
              id="id"
              name="noOfMonths"
              defaultData={ParentData?.defaultNoOfMonths}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>
          
          <div>
            <span className='fs-semibold fs-7'>Vehicle Type</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Vehicle Type"
              SelectData={ParentData?.vehicleTypes || []}
              displayFields={["name"]}
              id="id"
              name="vehicle_type"
              defaultData={ParentData?.defaultVehicleType}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>Vehicle Category</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Vehicle Category"
              SelectData={ParentData?.vehicleCategories || []}
              displayFields={["name"]}
              id="id"
              name="vehicle_category"
              defaultData={ParentData?.defaultVehicleCategory}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>Previous Source</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Previous Source"
              SelectData={ParentData?.previousSources || []}
              displayFields={["option"]}
              id="id"
              name="previousSource"
              defaultData={ParentData?.defaultPreviousSource}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>
          
          <div>
            <span className='fs-semibold fs-7'>Previous Service Provider</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Previous Service Provider"
              SelectData={ParentData?.previousServiceProviders || []}
              displayFields={["option"]}
              id="id"
              name="previousServiceProvider"
              defaultData={ParentData?.defaultPreviousServiceProvider}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>

          <div>
            <span className='fs-semibold fs-7'>Previous Payment Recieved</span>
            <AdminMultiSelectFilter
              onChangeHandler={ParentData?.onChangeHandler}
              HeaderTitle="Select Previous Payment Recieved"
              SelectData={ParentData?.previousPaymentRecieved || []}
              displayFields={["option"]}
              id="id"
              name="previousPaymentRecieved"
              defaultData={ParentData?.defaultPreviousPaymentRecieved}
              searchable={false}
              setSeacrch={false}
              isLoading={false}
              filters={null}
            />
          </div>
        </div>
      </div>
  )
}

export default RenewalDataFilter
