import React from 'react'
import Select from 'react-select'
const WarehouseItemsFilters = ({ ParentData }: any) => {
 let toDate = "to_date"
 
 const formatOptions = (data: any[], keys: string[], idKey: string) => {
   return data?.map((item: any) => ({
     value: item[idKey],
     label: keys.map(key => item[key]).join(' - ')
   })) || []
 }

 const handleMultiSelect = (selectedOptions: any, name: string) => {
   const values = selectedOptions?.map((option: any) => option.value) || []
   ParentData?.onChangeHandler({ value: values }, name, true)
 }
  return (
   <div className='p-6 w-325px w-md-375px'>
     <h3 className='fw-bold text-dark mb-8'>Search & Filteration</h3>
      <div className='row mb-2 mb-md-0'>
       <div className='col-md-6'>
         <span>To Date</span>
         <input
           type='date'
           style={{ height: '38px' }}
           name={toDate}
           value={ParentData?.payloads[toDate]}
           onChange={(event: any) =>
             ParentData?.onChangeHandler(event.target, toDate, true)
           }
           className='w-100 form-control me-2 align-start'
         />
       </div>
     </div>
      <div className='mb-4'>
       <span className='fs-semibold fs-7'>Items</span>
       <Select
         isMulti
         options={formatOptions(ParentData?.inventoryItems || [], ["name"], "id")}
         onChange={(selected) => handleMultiSelect(selected, "item_id")}
         value={formatOptions(
           ParentData?.inventoryItems?.filter((item: any) => 
             ParentData?.payloads?.item_id?.includes(item.id)
           ) || [],
           ["name"],
           "id"
         )}
         className="mt-1"
         placeholder="Select Items"
       />
     </div>
      <div className='mb-4'>
       <span className='fs-semibold fs-7'>Warehouses</span>
       <Select
         isMulti
         options={formatOptions(ParentData?.warehouses || [], ["name"], "id")}
         onChange={(selected) => handleMultiSelect(selected, "warehouse_id")}
         value={formatOptions(
           ParentData?.warehouses?.filter((warehouse: any) => 
             ParentData?.payloads?.warehouse_id?.includes(warehouse.id)
           ) || [],
           ["name"],
           "id"
         )}
         className="mt-1"
         placeholder="Select Warehouses"
       />
     </div>
      <div className='mb-4'>
       <span className='fs-semibold fs-7'>Area Managers</span>
       <Select
         isMulti
         options={formatOptions(ParentData?.areaManagers || [], ["name"], "id")}
         onChange={(selected) => handleMultiSelect(selected, "area_manager_ids")}
         value={formatOptions(
           ParentData?.areaManagers?.filter((areaManager: any) => 
             ParentData?.payloads?.area_manager_ids?.includes(areaManager.id)
           ) || [],
           ["name"],
           "id"
         )}
         className="mt-1"
         placeholder="Select Area Managers"
       />
     </div>
    </div>
 )
}
export default WarehouseItemsFilters