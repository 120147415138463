
import { useEffect, useRef, useState } from 'react';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import 'handsontable/dist/handsontable.full.min.css';
import "react-tooltip/dist/react-tooltip.css";
import { renderToString } from 'react-dom/server';
import Handsontable from 'handsontable';
import { colWidthsHotTable, handleAfterSelectionRow, hotInstanceLoader, searchFieldKeyupCallback, cellRenderInner } from '../admins/adminUtils';

import moment from 'moment';
import MainContainerWrapper from '../../consts/mediaQueryLayout/MainContainerWrapper';
import LeftBox from '../../consts/mediaQueryLayout/LeftBox';
import SearchBoxModal from '../../consts/reuseModules/SearchBoxModal';
import RightBox from '../../consts/mediaQueryLayout/RightBox';
import Refresh from '../../consts/Refresh';
import { getRequest, postRequest } from '../../consts/Server/Requests';
import { URL_EndPoints } from '../../consts/Server/URL_EndPoints';
import IsLoading from '../../consts/IsLoading/IsLoading';
import { KTCardBody } from '../../../_metronic/helpers';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { AllFielSearch } from '../../consts/FieldSearch/AllFieldSearch';
import Filters from '../../consts/filters/Filters';
import RenewalDataFilter from './RenewalDataFilter';

registerAllModules();

const subscriptionTypes = [
    {
        name: "All",
        id: "",
    },    
    {
        name: "New",
        id: "New",
    },
    {
        name: "Renewal",
        id: "Renewal",
    },
    {
        name: "Resumed",
        id: "Resumed",
    },
]

const autopays = [
    {
        name: "All",
        id: "",
    },
    {
        name: "Autopay active",
        id: "true",
    },
    {
        name: "Autopay inactive",
        id: "false",
    }
]

const noOfMonths = [
    {
        name: "1 month",
        id: 1,
    },
    {
        name: "3 months",
        id: 3,
    },
    {
        name: "6 months",
        id: 6,
    },
]

const dataTypes = [
    {
        name: "Numbers",
        id: "Numbers",
    },
    {
        name: "Percentage",
        id: "Percentage",
    },
]

export const RenewalData = () => {
    const hotTableComponentRef = useRef<any>(null);
    const [search, setSearch] = useState<any>("")
    const [pending, setPending] = useState(false)
    const [filterData, setFilterData] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [columns, setColumns] = useState<any>([])
    const [filteredSelectedRows, setFilteredSelectedRows] = useState<any>([])
    const [frequencies, setFrequencies] = useState<any>([])
    const [vehicleCategories, setVehicleCategories] = useState<any>([])
    const [vehicleTypes, setVehicleTypes] = useState<any>([])
    const [previousPaymentRecieved, setPreviousPaymentRecieved] = useState<any>([])
    const [previousSources, setPreviousSources] = useState<any>([])
    const [previousServiceProviders, setPreviousServiceProviders] = useState<any>([])
    const [onboardedBy, setOnboardedBy] = useState<any>([])
    const [payload, setPayload] = useState<any>({
        startMonth: moment().startOf("month").format("YYYY-MM-DD"),
        endMonth: moment().endOf("month").format("YYYY-MM-DD"),
        subscriptionType: [],
        isAutopay: "",
        noOfMonths: [],
        frequencyid: [],
        vehicle_category: [],
        vehicle_type: [],
        dataType: "Numbers",
        previousPaymentRecieved: [],
        previousSource: [],
        previousServiceProvider: [],
        onboardedBy: [],
    })


    let firstTimeNotRun = useRef<any>(false)
    useEffect(() => {
      let x = setTimeout(() => {
        const filterSearchData = AllFielSearch(data, search)
        firstTimeNotRun?.current === true && setFilterData(filterSearchData)
        if (firstTimeNotRun?.current === false) {
          firstTimeNotRun.current = true
        }
      }, 2000)
      return () => {
        clearTimeout(x)
      }
    }, [search])


    async function getPaymentCountsList() {
        setFilteredSelectedRows([])
        setPending(true)
        const ticketCountData = await postRequest(URL_EndPoints(null)?.getRenewalData, payload, setPending)
        setColumns(ticketCountData?.data?.columns || [])
        setFilterData(ticketCountData?.data?.data || [])
        setData(ticketCountData?.data?.data || [])
    }


    const getActionsList = async () => {
        const frequencies = await getRequest(URL_EndPoints(null)?.getAllFrequencies, null)
        const vehicleCategories = await postRequest(URL_EndPoints(null)?.getVehicleCategories , null, null)
        const vehicleTypes = await postRequest(URL_EndPoints(null)?.getVehicleTypes , null, null)
        const questionOptions = await getRequest(URL_EndPoints(null)?.getQuestionOptions , null)
        const onboardedBy = await getRequest(URL_EndPoints(null)?.getSalesAgents , null)
        setFrequencies(frequencies?.data?.data || [])
        setVehicleCategories(vehicleCategories?.data?.data?.map((ele: any) => ({ name: ele?.category_name, id: ele?.id })) || [])
        setVehicleTypes(vehicleTypes?.data?.data?.map((ele: any) => ({ name: ele?.vehicle_type, id: ele?.id })) || [])
        setPreviousPaymentRecieved(questionOptions?.data?.data?.prices || [])
        setPreviousSources(questionOptions?.data?.data?.sources || [])
        setPreviousServiceProviders(questionOptions?.data?.data?.serviceProviders || [])
        setOnboardedBy(onboardedBy?.data?.data || [])
    }

    useEffect(() => {
        getPaymentCountsList()
        getActionsList()
    }, [])

    const searchHandler = () => {
        getPaymentCountsList()
    }

    const onChangeHandler = (event: any, name: any) => {
        console.log(event, name)
        setPayload((payload: any) => ({ ...payload, [name]: event ? event?.value : event }))
    }

    const removeUnderScore = (str: any) => {
        if (str?.includes("_")) {
            return str?.split("_")?.join(" ")?.toUpperCase()
        }
        else {
            return str?.toUpperCase()
        }
    }
 
    useEffect(() => {
        hotInstanceLoader(hotTableComponentRef)
    }, []);

    const cellRenderer = (...args: any) => {
        cellRenderInner(args, Handsontable)
    }

    const shortWords = (sentence: any, index: any) => {
        if (index < 2) { return removeUnderScore(sentence) }
        else if (index == 1 || sentence?.includes("_")) { removeUnderScore(sentence) }
        return removeUnderScore(sentence?.split(' ')[1])
    }

    const handleAfterSelection = (row: any, column: any, row2: any, column2: any, preventScrolling: any, selectionLayerLevel: any) => {
        handleAfterSelectionRow(setFilteredSelectedRows, row, column, row2, column2, preventScrolling, selectionLayerLevel)
    };


    return (
        <>
            <KTCardBody>
                <div className='mb-4 fs-4 fw-semibold'>Renewal Data</div>
                <MainContainerWrapper>
                    <LeftBox>
                        <SearchBoxModal search={search} setSearch={setSearch} />
                    </LeftBox>
                    <RightBox>
                        <Refresh refreshNow={searchHandler} />
                    </RightBox>
                </MainContainerWrapper>

                {/* hint components */}
                
                <div className='row'>
                    <div className='col-md-10'>
                        {pending ? <IsLoading /> : <HotTable
                        ref={hotTableComponentRef}
                        style={{ zIndex: 1 }}
                        data={filterData}
                        columns={columns?.map((ele: any, index: any) => {
                            return {
                                title: renderToString(<div id={ele} >{shortWords(ele, index)} </div>),
                                type: 'text',
                                data: ele,
                                renderer: cellRenderer
                            }
                        })}
                        className='renewalData'
                        filters={true}
                        dropdownMenu={true}
                        colWidths={colWidthsHotTable(columns, 2, 150, 55)}
                        width="100%"
                        height={"80vh"}
                        title='Renewal Data'
                        rowHeaders={true}
                        colHeaders={true}
                        fixedColumnsLeft={2}
                        contextMenu={true}
                        manualColumnFreeze={true}
                        autoWrapRow={true}
                        autoWrapCol={true}
                        licenseKey="non-commercial-and-evaluation"
                        autoRowSize={true}
                        autoColumnSize={true}
                        readOnly={true}
                        manualRowResize={true}
                        manualColumnResize={true}
                        manualRowMove={true}
                        multiColumnSorting={true}
                        search={{ callback: searchFieldKeyupCallback }}
                        afterSelection={handleAfterSelection}
                    // afterDeselect={handleAfterDeselect}
                    />}      
                    </div>
                    <div className='col-md-2'>
                        <Filters
                            ParentData={{
                                handleActions: searchHandler,
                                payloads: payload,
                                onChangeHandler: onChangeHandler,
                                frequencies: frequencies,
                                vehicleCategories: vehicleCategories,
                                vehicleTypes: vehicleTypes,
                                subscriptionTypes: subscriptionTypes,
                                autopays: autopays,
                                noOfMonths: noOfMonths,
                                dataTypes: dataTypes,
                                previousPaymentRecieved: previousPaymentRecieved,
                                previousSources: previousSources,
                                previousServiceProviders: previousServiceProviders,
                                onboardedBy: onboardedBy,
                                defaultFrequency: null,
                                defaultSubscriptionType: null,
                                defaultAutopay: null,
                                defaultNoOfMonths: null,
                                defaultDataType: null,
                                defaultPreviousPaymentRecieved: null,
                                defaultPreviousSource: null,
                                defaultPreviousServiceProvider: null,
                                defaultOnboardedBy: null,
                            }}
                            Component={RenewalDataFilter}
                        />                    
                    </div>
                </div>
            </KTCardBody>

            {columns?.map((ele: any) => (
                <ReactTooltip
                    anchorId={ele}
                    place={"top"}
                    className="bg-dark"
                    content={ele}
                />
            ))}

        </>
    );
};

