import { useState } from 'react'
import { KTCardBody } from '../../../_metronic/helpers'
import { Tabs, Tab } from 'react-bootstrap'
import { LeadsVsSourceStatusList } from './LeadsVsSourceStatusList'
import { LeadsVsCampaign } from './LeadsVsCampaign'
import { LeadsVsSourceSubStatusList } from './LeadsVsSourceSubStatusList'

export const LeadCounts = () => {
  const [activeTab, setActiveTab] = useState('bySource')

  return (
    <KTCardBody>
      <div className='mb-4 fs-4 fw-semibold'>Lead Counts</div>
      
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k || 'bySource')}
        className='mb-4'
      >
        <Tab eventKey='bySource' title='Count by Source'>
          <LeadsVsSourceStatusList />
        </Tab>
        <Tab eventKey='byCampaign' title='Count by Campaign'>
          <LeadsVsCampaign />
        </Tab>
        <Tab eventKey='bySubStatus' title='Count by Sub Status'>
          <LeadsVsSourceSubStatusList />
        </Tab>
      </Tabs>
    </KTCardBody>
  )
}